import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { getDatePublished, getTimePublished, getTimezoneName } from "../../utils.js/dateHelpers.js"
import GridLoader from 'react-spinners/GridLoader';
import '../GlobalStyles.css';
import '../search/Search.css';
import { Tab, Tabs } from 'react-bootstrap';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale } from 'chart.js';
import 'chartjs-adapter-date-fns';
import annotationPlugin from 'chartjs-plugin-annotation';
import SideBar from '../search/SideBar.js';
import DataTable from '../search/DataTable.js';
import NarrativeHistory from './NarrativeHistory.js';
import ClusterSimilarity from '../visualizations/ClusterSimilarity.js';
import DonutChart from '../visualizations/DonutChart.js';
import Timeline from '../visualizations/Timeline.js';
import SearchableCluster from '../search/SearchableCluster.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  annotationPlugin
);

function NarrativeAnalysis({
  baseRequest,
  customTrackerCreator = false,
  countrySearch = true,
  countryColumn = true,
  languageSearch = true,
  languageColumn = true,
  sourceOptions: propSourceOptions,
  preset_query = "",
  excludePast24Hours = false,
  showSentiment = false,
  fringeNarrative = false,
  chinaNarrative = false,
  turkeyNarrative = false,
  iranNarrative = false,
  russiaNarrative = false,
  usaNarrative = false,
  hamasNarrative = false,
  hezbollahNarrative = false,
  alQaedaNarrative = false,
  bokoHaramNarrative = false,
  isisNarrative = false,
  utahNarrative = false,
  spaceForceNarrative = false
}) {

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth <= 768);
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const [activeTab, setActiveTab] = useState('insights');

  // Data states
  const [clusterData, setClusterData] = useState([]);
  const [allArticleData, setAllArticleData] = useState([]);
  const [articleData, setArticleData] = useState([]);

  // Loading states
  const [clusterDataIsLoading, setClusterDataIsLoading] = useState(true);
  const [articleDataIsLoading, setArticleDataIsLoading] = useState(true);

  // Filter states
  const [articleFilters, setArticleFilters] = useState({});

  // TSNE dictionary for similarity chart
  const [articleTsneDict, setArticleTsneDict] = useState({});

  // Misc. states
  const [initialLoadDone, setInitialLoadDone] = useState(false);

  // Time/Date filters
  const timeRangeOptions = excludePast24Hours ? [
    { label: "Past Week", value: "Past Week" },
    { label: "Past Month", value: "Past Month" },
    { label: "Past 4 Months", value: "Past 4 Months" },
    { label: "Custom Range", value: "Custom Range" },
  ] : [
    { label: "Past 48 Hours", value: "Past 48 Hours" },
    { label: "Past Week", value: "Past Week" },
    { label: "Past Month", value: "Past Month" },
    { label: "Past 4 Months", value: "Past 4 Months" },
    { label: "Custom Range", value: "Custom Range" },
  ];
  const [selectedTimeRange, setSelectedTimeRange] = useState(
    timeRangeOptions.find(option => option.value === "Past 48 Hours")
  );

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [dateError, setDateError] = useState('');

  // Source, language, country, topic, sentiment filters
  const [sourceOptions, setSourceOptions] = useState([]);
  const [languageOptions, setLanguageOptions] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [sentimentOptions, setSentimentOptions] = useState([]);
  const [topicOptions, setTopicOptions] = useState([]);

  const [selectedSources, setselectedSources] = useState(null);
  const [selectedLanguages, setSelectedLanguages] = useState(null);
  const [selectedCountries, setselectedCountries] = useState(null);
  const [selectedTopics, setselectedTopics] = useState(null);
  const [selectedSentiments, setSelectedSentiments] = useState(null);
  const [selectedSemanticSearch, setSemanticSearch] = useState('');

  // Check if we have no results (after loading)
  const hasNoResults = !articleDataIsLoading && articleData.length === 0;

  // Helpers
  const mapSentiment = (score) => {
    if (score <= -0.6) return { text: "Strongly Negative", color: '#DE0A26', textColor: "#FFFFFF" };
    if (score > -0.6 && score <= -0.2) return { text: "Moderately Negative", color: '#F7B4BB', textColor: "#222222" };
    if (score > -0.2 && score <= 0.2) return { text: "Neutral", color: '#CCCCCC', textColor: "#222222" };
    if (score > 0.2 && score <= 0.6) return { text: "Moderately Positive", color: '#B5FE89', textColor: "#222222" };
    if (score > 0.6) return { text: "Strongly Positive", color: '#44B200', textColor: "#FFFFFF" };
    return { text: "Neutral", color: '#CCCCCC', textColor: "#222222" };
  };

  // Aggregate & slice for DonutChart
  const getAllSources = (articleData) => {
    const sourceInfo = articleData.reduce((acc, article) => {
      const { source, country } = article;
      if (source) {
        if (acc[source]) {
          acc[source].count += 1;
        } else {
          acc[source] = { country, count: 1 };
        }
      }
      return acc;
    }, {});

    const sortedArray = Object.entries(sourceInfo)
      .map(([src, val]) => ({ source: src, country: val.country, count: val.count }))
      .sort((a, b) => b.count - a.count);

    const topFive = sortedArray.slice(0, 5);
    const other = sortedArray.slice(5);
    const otherCount = other.reduce((sum, item) => sum + item.count, 0);

    const result = {};
    topFive.forEach(item => {
      result[item.source] = { country: item.country, count: item.count };
    });
    if (otherCount > 0) {
      result["Other"] = { country: null, count: otherCount };
    }
    return result;
  };

  const getAllCountries = (articleData) => {
    const countryInfo = articleData.reduce((acc, { country }) => {
      if (country) {
        acc[country] = acc[country] ? { count: acc[country].count + 1 } : { count: 1 };
      }
      return acc;
    }, {});

    const sortedArray = Object.entries(countryInfo)
      .map(([country, val]) => ({ country, count: val.count }))
      .sort((a, b) => b.count - a.count);

    const topFive = sortedArray.slice(0, 5);
    const other = sortedArray.slice(5);
    const otherCount = other.reduce((sum, item) => sum + item.count, 0);

    const result = {};
    topFive.forEach(item => {
      result[item.country] = { count: item.count };
    });
    if (otherCount > 0) {
      result["Other"] = { count: otherCount };
    }
    return result;
  };

  const getAllLanguages = (articleData) => {
    const langInfo = articleData.reduce((acc, { language }) => {
      if (language) {
        acc[language] = acc[language] ? { count: acc[language].count + 1 } : { count: 1 };
      }
      return acc;
    }, {});

    const sortedArray = Object.entries(langInfo)
      .map(([language, val]) => ({ language, count: val.count }))
      .sort((a, b) => b.count - a.count);

    const topFive = sortedArray.slice(0, 5);
    const other = sortedArray.slice(5);
    const otherCount = other.reduce((sum, item) => sum + item.count, 0);

    const result = {};
    topFive.forEach(item => {
      result[item.language] = { count: item.count };
    });
    if (otherCount > 0) {
      result["Other"] = { count: otherCount };
    }
    return result;
  };

  const getAllLocations = (articleData) => {
    const locationInfo = articleData.reduce((acc, { locations }) => {
      if (locations && Array.isArray(locations)) {
        const uniqueLocations = new Set(locations);
        uniqueLocations.forEach(loc => {
          if (acc[loc]) {
            acc[loc].count += 1;
          } else {
            acc[loc] = { count: 1 };
          }
        });
      }
      return acc;
    }, {});

    const sortedArray = Object.entries(locationInfo)
      .map(([location, val]) => ({ location, count: val.count }))
      .sort((a, b) => b.count - a.count);

    const topFive = sortedArray.slice(0, 5);
    const other = sortedArray.slice(5);
    const otherCount = other.reduce((sum, item) => sum + item.count, 0);

    const result = {};
    topFive.forEach(item => {
      result[item.location] = { count: item.count };
    });
    if (otherCount > 0) {
      result["Other"] = { count: otherCount };
    }
    return result;
  };

  const getAllOrganizations = (articleData) => {
    const orgInfo = articleData.reduce((acc, { organizations }) => {
      if (organizations && organizations.length > 0) {
        organizations.forEach(org => {
          if (acc[org]) {
            acc[org].count += 1;
          } else {
            acc[org] = { count: 1 };
          }
        });
      }
      return acc;
    }, {});

    const sortedArray = Object.entries(orgInfo)
      .map(([organization, val]) => ({ organization, count: val.count }))
      .sort((a, b) => b.count - a.count);

    const topFive = sortedArray.slice(0, 5);
    const other = sortedArray.slice(5);
    const otherCount = other.reduce((sum, item) => sum + item.count, 0);

    const result = {};
    topFive.forEach(item => {
      result[item.organization] = { count: item.count };
    });
    if (otherCount > 0) {
      result["Other"] = { count: otherCount };
    }
    return result;
  };

  const getAllPersons = (articleData) => {
    const personInfo = articleData.reduce((acc, { persons }) => {
      if (persons && Array.isArray(persons)) {
        persons.forEach(per => {
          if (acc[per]) {
            acc[per].count += 1;
          } else {
            acc[per] = { count: 1 };
          }
        });
      }
      return acc;
    }, {});

    const sortedArray = Object.entries(personInfo)
      .map(([name, val]) => ({ name, count: val.count }))
      .sort((a, b) => b.count - a.count);

    const topFive = sortedArray.slice(0, 5);
    const other = sortedArray.slice(5);
    const otherCount = other.reduce((sum, item) => sum + item.count, 0);

    const result = {};
    topFive.forEach(item => {
      result[item.name] = { count: item.count };
    });
    if (otherCount > 0) {
      result["Other"] = { count: otherCount };
    }
    return result;
  };

  const getAllBasicEmotions = (articleData) => {
    const emotionInfo = articleData.reduce((acc, { basic_emotion }) => {
      if (basic_emotion) {
        if (acc[basic_emotion]) {
          acc[basic_emotion].count += 1;
        } else {
          acc[basic_emotion] = { count: 1 };
        }
      }
      return acc;
    }, {});

    const sortedArray = Object.entries(emotionInfo)
      .map(([emotion, val]) => ({ emotion, count: val.count }))
      .sort((a, b) => b.count - a.count);

    // We only slice to 7 to keep the legend from being too large.
    const finalEmotions = sortedArray.slice(0, 7).reduce((acc, item) => {
      acc[item.emotion] = { count: item.count };
      return acc;
    }, {});

    return finalEmotions;
  };

  const getAllCharged = (articleData) => {
    const chargedInfo = articleData.reduce((acc, article) => {
      let { charged } = article;
      if (charged !== "Yes") charged = "No"; // Force 'Yes'/'No' only

      if (acc[charged]) {
        acc[charged].count += 1;
      } else {
        acc[charged] = { count: 1 };
      }
      return acc;
    }, {});

    const sortedArray = Object.entries(chargedInfo)
      .map(([charge, val]) => ({ charge, count: val.count }))
      .sort((a, b) => b.count - a.count);

    // We only slice to 7 to keep the legend from being too large.
    const finalCharged = sortedArray.slice(0, 7).reduce((acc, item) => {
      acc[item.charge] = { count: item.count };
      return acc;
    }, {});

    return finalCharged;
  };

  // Convert t-SNE array to a dictionary
  const convertTsneFormat = (articleTsneArray) => {
    const articleTsneObject = {};
    articleTsneArray.forEach(article => {
      articleTsneObject[article.id] = [article.x, article.y];
    });
    return articleTsneObject;
  };

  // Identify cluster ID from URL
  const fullUrl = window.location.href;
  const cluster_id = fullUrl.split("/")[4];

  // Filters for the cluster data
  const clusterFilters = {
    query: "",
    sources: [],
    countries: [],
    topics: [],
    keywords: [],
    date_published: [null, null],
    links: [],
    tracker_type: [],
    cluster: true,
    article_id: [cluster_id],
    latest: true,
    fringe: fringeNarrative,
    china: chinaNarrative,
    turkey: turkeyNarrative,
    iran: iranNarrative,
    russia: russiaNarrative,
    united_states: usaNarrative,
    hamas: hamasNarrative,
    hezbollah: hezbollahNarrative,
    boko_haram: bokoHaramNarrative,
    al_qaeda: alQaedaNarrative,
    isis: isisNarrative,
    space: spaceForceNarrative,
    utah: utahNarrative
  };

  // 1. Fetch cluster data on mount
  useEffect(() => {
    fetchClusterData();
    // eslint-disable-next-line
  }, []);


  const fetchClusterData = async () => {
    try {
      setClusterDataIsLoading(true);
      const response = await axios.post('https://fopotracking.ue.r.appspot.com/search', clusterFilters);
      const rawClusterData = response.data;

      if (!rawClusterData || rawClusterData.length === 0) {
        setClusterData([]);
        setClusterDataIsLoading(false);
        return;
      }

      const articleIds = rawClusterData[0]?.source?.article_ids || [];
      setArticleFilters({
        query: "",
        sources: [],
        countries: [],
        topics: [],
        date_published: [],
        links: [],
        tracker_type: [],
        article_id: articleIds,
        latest: true,
        fringe: fringeNarrative,
        china: chinaNarrative,
        turkey: turkeyNarrative,
        iran: iranNarrative,
        russia: russiaNarrative,
        united_states: usaNarrative,
        hamas: hamasNarrative,
        hezbollah: hezbollahNarrative,
        boko_haram: bokoHaramNarrative,
        al_qaeda: alQaedaNarrative,
        isis: isisNarrative,
        space: spaceForceNarrative,
        utah: utahNarrative
      });

      let records = [];
      let articleTsneArray = [];

      rawClusterData.forEach(item => {
        const {
          cluster_size,
          article_ids,
          cluster_title,
          cluster_summary,
          creation_timestamp,
          update_timestamp,
          persons,
          topics,
          countries,
          image_url,
          article_embeddings_reduced,
          summary_history,
          title_history,
          sub_clusters,
          sub_narrative_history
        } = item.source;

        records.push({
          cluster_id: item.id,
          cluster_size,
          article_ids,
          current_title: cluster_title,
          current_summary: cluster_summary,
          creation_timestamp_utc: creation_timestamp.replace(" ", "T") + "+00:00",
          update_timestamp_utc: update_timestamp.replace(" ", "T") + "+00:00",
          persons,
          topics,
          countries,
          image_link: image_url,
          article_embeddings_reduced,
          summary_history,
          title_history,
          sub_clusters,
          sub_narrative_history
        });

        if (article_embeddings_reduced && Array.isArray(article_embeddings_reduced)) {
          articleTsneArray = articleTsneArray.concat(article_embeddings_reduced);
        }
      });

      setClusterData(records);
      setArticleTsneDict(convertTsneFormat(articleTsneArray));
      setClusterDataIsLoading(false);
    } catch (error) {
      // console.error('Error fetching cluster data:', error);
      setClusterDataIsLoading(false);
    }
  };

  // 2. Fetch article data whenever articleFilters is set (first load or whenever filters update)
  useEffect(() => {
    // Check if articleFilters has a non-empty article_id (or any key you rely on)
    if (articleFilters && articleFilters.article_id && articleFilters.article_id.length > 0 && !initialLoadDone) {
      fetchArticleData();
      setInitialLoadDone(true);
    }
  }, [articleFilters, initialLoadDone]);


  const fetchArticleData = async (filters = articleFilters) => {
    try {
      setArticleDataIsLoading(true);
      const response = await axios.post('https://fopotracking.ue.r.appspot.com/search', filters);
      let records = [];

      response.data.forEach(item => {
        const {
          date_published,
          link,
          word_count,
          title,
          source_url,
          country,
          language,
          website_name,
          country_rank,
          site_rank,
          topics,
          locations,
          organizations,
          persons,
          summary,
          sentiment_compound,
          charged,
          basic_emotion,
          image
        } = item.source;

        const publishedUtc = date_published.replace(" ", "T") + "+00:00";
        const dateObj = new Date(publishedUtc);

        records.push({
          article_id: item.id,
          date_time_published_utc: publishedUtc,
          date_published: getDatePublished(publishedUtc),
          time_published: getTimePublished(publishedUtc),
          timezone_name: new Intl.DateTimeFormat('en-US', { timeZoneName: 'short' })
            .format(dateObj)
            .split(', ')[1],
          link,
          word_count,
          title,
          source_url,
          country,
          language,
          source: website_name,
          country_rank,
          site_rank,
          topics,
          locations,
          organizations,
          persons,
          summary,
          image_link: image || null,
          sentiment_compound: sentiment_compound || null,
          charged: charged || null,
          basic_emotion: basic_emotion || null
        });
      });

      if (!initialLoadDone) {
        setAllArticleData(records);
      }
      setArticleData(records);
      setArticleDataIsLoading(false);
    } catch (error) {
      console.error('Error fetching article data:', error);
      setArticleDataIsLoading(false);
    }
  };

  // Update filter function
  const updateFilters = useCallback((newFilters) => {
    setArticleFilters(prevFilters => ({ ...prevFilters, ...newFilters }));
  }, []);

  // Sidebar handlers
  const handleSourceChange = selectedOption => {
    setselectedSources(selectedOption);
    updateFilters({ sources: selectedOption.map(option => option.value) });
  };

  const handleLanguageChange = selectedOption => {
    setSelectedLanguages(selectedOption);
    updateFilters({ languages: selectedOption.map(option => option.value) });
  };

  const handleCountryChange = selectedOption => {
    setselectedCountries(selectedOption);
    updateFilters({ countries: selectedOption.map(option => option.value) });
  };

  const handleTopicChange = selectedOption => {
    setselectedTopics(selectedOption);
    updateFilters({ topics: selectedOption.map(option => option.value) });
  };

  const mapSentimentToRange = (text) => {
    switch (text) {
      case "Strongly Negative": return [-1.0, -0.6];
      case "Moderately Negative": return [-0.6, -0.2];
      case "Neutral": return [-0.2, 0.2];
      case "Moderately Positive": return [0.2, 0.6];
      case "Strongly Positive": return [0.6, 1.0];
      default: return [null, null];
    }
  };

  const handleSentimentChange = selectedOption => {
    setSelectedSentiments(selectedOption);
    const selectedRanges = selectedOption.map(option => mapSentimentToRange(option.value));
    const minRange = Math.min(...selectedRanges.map(range => range[0]));
    const maxRange = Math.max(...selectedRanges.map(range => range[1]));
    updateFilters({ sentiment_compound: [minRange, maxRange] });
  };

  const handleSemanticSearchChange = (e) => {
    const searchValue = e.target.value;
    setSemanticSearch(searchValue);
  };


  const handleSearchClick = async () => {
    await fetchArticleData();
  };

  const handleRefreshClick = async () => {
    await fetchArticleData();
  };

  // Date Range handlers
  const formatDateToString = (date) => {
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    });
  };

  const validateDateFormat = (date) => {
    const regex = /^(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01])\/\d{4}$/;
    return regex.test(date);
  };

  const validateDateRange = (start, end) => {
    if (!start) return true;
    const defaultEndDate = new Date().toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' });
    const endDateObj = end ? new Date(end) : new Date(defaultEndDate);
    const startDateObj = new Date(start);
    return startDateObj < endDateObj;
  };

  const handleStartDateChange = (e) => {
    const newStartDate = e.target.value;
    setStartDate(newStartDate);
    if (!newStartDate) {
      setDateError('');
      return updateFilters({ date_published: [newStartDate, endDate] });
    }

    if (!validateDateFormat(newStartDate)) {
      setDateError('Please use MM/DD/YYYY.');
    } else if (!validateDateRange(newStartDate, endDate)) {
      setDateError('Start date must be before end date.');
    } else {
      setDateError('');
      updateFilters({ date_published: [newStartDate, endDate] });
    }
  };

  const handleEndDateChange = (e) => {
    const newEndDate = e.target.value;
    setEndDate(newEndDate);
    if (!newEndDate) {
      setDateError('');
      return updateFilters({ date_published: [startDate, newEndDate] });
    }

    if (!validateDateFormat(newEndDate)) {
      setDateError('Please use MM/DD/YYYY.');
    } else if (!validateDateRange(startDate, newEndDate)) {
      setDateError('Start date must be before end date.');
    } else {
      setDateError('');
      updateFilters({ date_published: [startDate, newEndDate] });
    }
  };

  const handleTimeRangeChange = (selectedOption) => {
    setSelectedTimeRange(selectedOption);

    const now = new Date();
    let startDateTemp = now;
    let endDateTemp = new Date(now.setDate(now.getDate() + 1));

    switch (selectedOption.value) {
      case "Past 48 Hours":
        startDateTemp = new Date(new Date().setDate(now.getDate() - 1));
        break;
      case "Past Week":
        startDateTemp = new Date(new Date().setDate(now.getDate() - 7));
        break;
      case "Past Month":
        startDateTemp = new Date(new Date().setMonth(now.getMonth() - 1));
        break;
      case "Past 4 Months":
        // Example logic: If you need a specific date, adjust accordingly
        startDateTemp = new Date('2024-01-01T00:00:00');
        break;
      case "Custom Range":
      default:
        return;
    }

    const formattedStart = formatDateToString(startDateTemp);
    const formattedEnd = formatDateToString(endDateTemp);

    setStartDate(formattedStart);
    setEndDate(formattedEnd);
    updateFilters({ date_published: [formattedStart, formattedEnd] });
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      updateFilters({ query: selectedSemanticSearch });
    }, 500);
    return () => clearTimeout(timer);
  }, [selectedSemanticSearch, updateFilters]);



  // Once we have the full data set, build out filter options
  useEffect(() => {
    if (allArticleData.length > 0) {
      const srcOptions = [...new Set(allArticleData.map(a => `${a.source} - ${a.source_url}`))]
        .sort()
        .map(src => ({ label: src, value: src }));
      setSourceOptions(srcOptions);

      const langOptions = [...new Set(allArticleData.map(a => a.language))]
        .sort()
        .map(lang => ({ label: lang, value: lang }));
      setLanguageOptions(langOptions);

      const ctryOptions = [...new Set(allArticleData.map(a => a.country))]
        .sort()
        .map(c => ({ label: c, value: c }));
      setCountryOptions(ctryOptions);

      const sentiments = [...new Set(allArticleData.map(a => mapSentiment(a.sentiment_compound).text))]
        .sort()
        .map(sent => ({ label: sent, value: sent }));
      setSentimentOptions(sentiments);

      const tpcOptions = [...new Set(allArticleData.flatMap(a => a.topics))]
        .sort()
        .map(t => ({ label: t, value: t }));
      setTopicOptions(tpcOptions);
    }
  }, [allArticleData]);

  // Sidebar component
  const sidebar = (
    <div className="sidebar">
      <SideBar
        sourceOptions={sourceOptions}
        selectedSources={selectedSources}
        onSourceChange={handleSourceChange}

        languageOptions={languageOptions}
        selectedLanguages={selectedLanguages}
        onLanguageChange={handleLanguageChange}
        languageSearch={languageSearch}

        countryOptions={countryOptions}
        selectedCountries={selectedCountries}
        onCountryChange={handleCountryChange}
        countrySearch={countrySearch}

        topicOptions={topicOptions}
        selectedTopics={selectedTopics}
        onTopicChange={handleTopicChange}

        sentimentOptions={sentimentOptions}
        selectedSentiments={selectedSentiments}
        onSentimentChange={handleSentimentChange}

        timeRangeOptions={timeRangeOptions}
        selectedTimeRange={selectedTimeRange}
        onTimeRangeChange={handleTimeRangeChange}

        selectedSemanticSearch={selectedSemanticSearch}
        onSemanticSearchChange={handleSemanticSearchChange}

        startDate={startDate}
        onStartDateChange={handleStartDateChange}
        endDate={endDate}
        onEndDateChange={handleEndDateChange}
        dateError={dateError}

        handleSearchClick={handleSearchClick}
        handleRefreshClick={handleRefreshClick}

        trackerNameError={false}
        customTrackerCreator={false}
        disabled={activeTab === 'related'}
      />
    </div>
  );

  // Narrative History tab content
  let clusterDataContent;
  if (clusterDataIsLoading) {
    clusterDataContent = (
      <div style={{ textAlign: "center" }}>
        <br /><br />
        <GridLoader />
      </div>
    );
  } else {
    clusterDataContent = (
      <div>
        <NarrativeHistory data={clusterData} />
      </div>
    );
  }

  // Timeline tab content
  let timelineDataContent;
  if (clusterDataIsLoading) {
    timelineDataContent = (
      <div style={{ textAlign: "center" }}>
        <br /><br />
        <GridLoader />
      </div>
    );
  } else {
    timelineDataContent = (
      <div>
        <Timeline data={clusterData}
          fringeCluster={fringeNarrative}
          chinaCluster={chinaNarrative}
          turkeyCluster={turkeyNarrative}
          iranCluster={iranNarrative}
          russiaCluster={russiaNarrative}
          usaCluster={usaNarrative}
          hamasCluster={hamasNarrative}
          hezbollahCluster={hezbollahNarrative}
          isisCluster={isisNarrative}
          bokoHaramCluster={bokoHaramNarrative}
          alQaedaCluster={alQaedaNarrative}
          spaceForceCluster={spaceForceNarrative}
          utahCluster={utahNarrative}
        />
      </div>
    );
  }

  // Articles table tab content
  let dataTableContent;
  if (articleDataIsLoading || clusterDataIsLoading) {
    dataTableContent = (
      <div className="data-table" style={{ textAlign: "center" }}>
        <br /><br />
        <GridLoader />
      </div>
    );
  } else if (hasNoResults) {
    dataTableContent = (
      <div className="data-table" style={{ textAlign: "center" }}>
        <br />
        <h2>No results found.</h2>
      </div>
    );
  } else {
    dataTableContent = (
      <div className="data-table">
        <DataTable
          data={articleData}
          semanticSearch={selectedSemanticSearch}
          countryColumn={countryColumn}
          languageColumn={languageColumn}
          enablePagination={true}
          fringeCluster={fringeNarrative}
          chinaCluster={chinaNarrative}
          turkeyCluster={turkeyNarrative}
          iranCluster={iranNarrative}
          russiaCluster={russiaNarrative}
          usaCluster={usaNarrative}
          hamasCluster={hamasNarrative}
          hezbollahCluster={hezbollahNarrative}
          isisCluster={isisNarrative}
          bokoHaramCluster={bokoHaramNarrative}
          alQaedaCluster={alQaedaNarrative}
          spaceForceCluster={spaceForceNarrative}
          utahCluster={utahNarrative}
          showCharged={true}
        />
      </div>
    );
  }

  // Key Insights tab content
  const renderInsightsTabContent = () => {
    if (!clusterData || clusterData.length === 0 || articleDataIsLoading) {
      return (
        <div style={{
          display: 'flex', flexDirection: 'column',
          justifyContent: 'center', alignItems: 'center'
        }}>
          <br />
          <h3>Analyzing Articles...</h3>
          <br />
          <GridLoader />
        </div>
      );
    }

    const Heading = isMobile ? 'h5' : 'h4';
    const creation = clusterData[0].creation_timestamp_utc;
    const update = clusterData[0].update_timestamp_utc;

    return (
      <>
        <div style={{ height: "0.5rem" }}></div>
        <i>
          Updated: {getDatePublished(update)}, {getTimePublished(update)} ({getTimezoneName(update)})
        </i>
        <br /><br />
        <Heading>Overview</Heading>
        {clusterData[0].current_summary.split("\n").map((item, index) => (
          <div key={index}>{item.replace(/^- /, "• ")}</div>
        ))}
        <br />
        {!isMobile && (
          <Heading>
            Started Tracking: {getDatePublished(creation)}, {getTimePublished(creation)} ({getTimezoneName(update)})
          </Heading>
        )}
        {articleDataIsLoading ? (
          <div style={{
            display: 'flex', flexDirection: 'column',
            justifyContent: 'center', alignItems: 'center'
          }}>
            {hasNoResults ? (
              <h3>No results found.</h3>
            ) : (
              <>
                <h3>Analyzing Articles...</h3>
                <br />
                <GridLoader />
              </>
            )}
          </div>
        ) : (
          <>
            {isMobile ? (
              <>
                <Heading>Started Tracking</Heading>
                {getDatePublished(creation)}, {getTimePublished(creation)} ({getTimezoneName(update)})
                <br /><br />
                <Heading>Relevant Articles</Heading>
                {articleData.length}
                <br /><br />
                <hr />
                <DonutChart
                  title="Article Sources"
                  labels={Object.keys(getAllSources(articleData))}
                  data={Object.values(getAllSources(articleData)).map(item => item.count)}
                />
                <DonutChart
                  title="Source Countries"
                  labels={Object.keys(getAllCountries(articleData))}
                  data={Object.values(getAllCountries(articleData)).map(item => item.count)}
                />
                <DonutChart
                  title="Article Languages"
                  labels={Object.keys(getAllLanguages(articleData))}
                  data={Object.values(getAllLanguages(articleData)).map(item => item.count)}
                />
                <DonutChart
                  title="Mentioned Locations"
                  labels={Object.keys(getAllLocations(articleData))}
                  data={Object.values(getAllLocations(articleData)).map(item => item.count)}
                />
                <DonutChart
                  title="Mentioned Organizations"
                  labels={Object.keys(getAllOrganizations(articleData))}
                  data={Object.values(getAllOrganizations(articleData)).map(item => item.count)}
                />
                <DonutChart
                  title="Mentioned Persons"
                  labels={Object.keys(getAllPersons(articleData))}
                  data={Object.values(getAllPersons(articleData)).map(item => item.count)}
                />
              </>
            ) : (
              <>
                <br />
                <Heading>Relevant Articles: {articleData.length}</Heading>
                <br />
                <div className="plans-container">
                  <div className="key-insights mb-2">
                    <DonutChart
                      title="Article Sources"
                      labels={Object.keys(getAllSources(articleData))}
                      data={Object.values(getAllSources(articleData)).map(item => item.count)}
                    />
                  </div>
                  <div className="key-insights mb-2">
                    <DonutChart
                      title="Source Countries"
                      labels={Object.keys(getAllCountries(articleData))}
                      data={Object.values(getAllCountries(articleData)).map(item => item.count)}
                    />
                  </div>
                </div>
                <br />
                <div className="plans-container">
                  <div className="key-insights mb-2">
                    <DonutChart
                      title="Mentioned Locations"
                      labels={Object.keys(getAllLocations(articleData))}
                      data={Object.values(getAllLocations(articleData)).map(item => item.count)}
                    />
                  </div>
                  <div className="key-insights mb-2">
                    <DonutChart
                      title="Article Languages"
                      labels={Object.keys(getAllLanguages(articleData))}
                      data={Object.values(getAllLanguages(articleData)).map(item => item.count)}
                    />
                  </div>
                </div>
                <br />
                <div className="plans-container">
                  <div className="key-insights mb-2">
                    <DonutChart
                      title="Mentioned Organizations"
                      labels={Object.keys(getAllOrganizations(articleData))}
                      data={Object.values(getAllOrganizations(articleData)).map(item => item.count)}
                    />
                  </div>
                  <div className="key-insights mb-2">
                    <DonutChart
                      title="Mentioned Persons"
                      labels={Object.keys(getAllPersons(articleData))}
                      data={Object.values(getAllPersons(articleData)).map(item => item.count)}
                    />
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </>
    );
  };

  // Visualization tab content
  const renderVisualizeTabContent = () => {
    if (isMobile) {
      return (
        <h3 style={{ textAlign: "center" }}>
          To see visualizations, please visit on a tablet, laptop, or desktop.
        </h3>
      );
    }
    if (articleDataIsLoading) {
      return (
        <div style={{
          display: 'flex', flexDirection: 'column',
          justifyContent: 'center', alignItems: 'center'
        }}>
          {hasNoResults ? (
            <h3>No results found.</h3>
          ) : (
            <>
              <br />
              <GridLoader />
            </>
          )}
        </div>
      );
    }
    return (
      <div className="chart-container">
        {!clusterDataIsLoading && articleData.length > 0 && (
          <>
            <div style={{ textAlign: 'center' }}>
              <h3>Article Similarity</h3>
              <i>Articles are grouped by subnarrative.</i>
            </div>
            <ClusterSimilarity
              articleTsne={articleTsneDict}
              articleData={articleData}
              clusterData={clusterData}
              fringeCluster={fringeNarrative}
              chinaCluster={chinaNarrative}
              turkeyCluster={turkeyNarrative}
              iranCluster={iranNarrative}
              russiaCluster={russiaNarrative}
              usaCluster={usaNarrative}
              hamasCluster={hamasNarrative}
              hezbollahCluster={hezbollahNarrative}
              isisCluster={isisNarrative}
              bokoHaramCluster={bokoHaramNarrative}
              alQaedaCluster={alQaedaNarrative}
              spaceForceCluster={spaceForceNarrative}
              utahCluster={utahNarrative}
            />
            <br /><br />
            <div style={{ textAlign: 'center' }}>
              <h3>Sentiment Analysis</h3>
              <i>Based on word choice, grammar, punctuation, and context.</i>
            </div>
            <br />
            <div className="plans-container">
              <div className="key-insights mb-2">
                <DonutChart
                  title="Emotional Tone"
                  labels={Object.keys(getAllBasicEmotions(articleData))}
                  data={Object.values(getAllBasicEmotions(articleData)).map(item => item.count)}
                  qualitativeParams={true}
                />
              </div>
              <div className="key-insights mb-2">
                <DonutChart
                  title="Uses Charged Language"
                  labels={Object.keys(getAllCharged(articleData))}
                  data={Object.values(getAllCharged(articleData)).map(item => item.count)}
                  qualitativeParams={true}
                />
              </div>
            </div>
          </>
        )}
      </div>
    );
  };

  return (
    <div>
      <div className="text-content" style={{ textAlign: 'center' }}>
        {!isMobile && <h1>Narrative Analysis</h1>}
        {clusterData.length > 0 && clusterData[0].current_title && (
          isMobile ? (
            <h4>{clusterData[0].current_title}</h4>
          ) : (
            <h3>{clusterData[0].current_title}</h3>
          )
        )}
      </div>
      <div className="searchable-container">
        <div className="sidebar">
          {sidebar}
        </div>
        <div className="data-table">
          <Tabs defaultActiveKey="insights" id="uncontrolled-tab-example" className="border-3" onSelect={(k) => setActiveTab(k)}>
            <Tab eventKey="insights" title={isMobile ? "Insights" : "Key Insights"}>
              {renderInsightsTabContent()}
            </Tab>
            <Tab eventKey="history" title={isMobile ? "History" : "Narrative History"}>
              {clusterDataContent}
            </Tab>
            <Tab eventKey="timeline" title="Timeline">
              {timelineDataContent}
            </Tab>
            {!isMobile && (
              <Tab eventKey="visualize" title="Visualize">
                <br />
                {renderVisualizeTabContent()}
              </Tab>
            )}
            <Tab eventKey="articles" title={isMobile ? "Articles" : "Relevant Articles"}>
              {dataTableContent}
            </Tab>
            <Tab eventKey="related" title={isMobile ? "Related" : "Related Stories"}>
              {clusterData.length > 0 && clusterData[0].current_summary && (
                <SearchableCluster
                  showSideBar={false}
                  preset_query={clusterData[0]?.current_summary}
                  baseRequest={{exclude_cluster_ids: [{cluster_id}]}}
                  fringeCluster={fringeNarrative}
                  turkeyCluster={turkeyNarrative}
                  chinaCluster={chinaNarrative}
                  iranCluster={iranNarrative}
                  russiaCluster={russiaNarrative}
                  usaCluster={usaNarrative}
                  hamasCluster={hamasNarrative}
                  alQaedaCluster={alQaedaNarrative}
                  bokoHaramCluster={bokoHaramNarrative}
                  hezbollahCluster={hezbollahNarrative}
                  isisCluster={isisNarrative}
                  spaceForceCluster={spaceForceNarrative}
                  utahCluster={utahNarrative}
                />
              )}
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  );
}

export default NarrativeAnalysis;