// utils/dateHelpers.js

/**
 * Converts a UTC date string to a formatted date string (MM/DD/YYYY)
 * @param {string} utcDateString - The UTC date string.
 * @returns {string} - Formatted date (e.g., "08/05/2025").
 */
export function getDatePublished(utcDateString) {
    const utcDate = new Date(utcDateString);
    return `${(utcDate.getMonth() + 1).toString().padStart(2, '0')}/${utcDate
        .getDate()
        .toString()
        .padStart(2, '0')}/${utcDate.getFullYear()}`;
}

/**
 * Converts a UTC date string to a formatted time string (HH:MM)
 * with minutes rounded to the nearest 10.
 * @param {string} utcDateString - The UTC date string.
 * @returns {string} - Formatted time (e.g., "14:30").
 */
export function getTimePublished(utcDateString) {
    const utcDate = new Date(utcDateString);
    let hours = utcDate.getHours();
    let minutes = utcDate.getMinutes();
    // Round minutes to the nearest 10
    minutes = Math.ceil(minutes / 10) * 10;
    if (minutes === 60) {
        minutes = 0;
        hours++;
    }
    if (hours === 24) {
        hours = 23;
        minutes = 50;
    }
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
}

/**
 * Formats a Date object as a UTC string in "YYYY-MM-DD HH:MM:SS" format.
 * @param {Date} date - The date to format.
 * @returns {string} - The formatted UTC date/time string.
 */
export function formatDateTime(date) {
    const pad = (n) => n.toString().padStart(2, '0');
    return `${date.getUTCFullYear()}-${pad(date.getUTCMonth() + 1)}-${pad(
        date.getUTCDate()
    )} ${pad(date.getUTCHours())}:${pad(date.getUTCMinutes())}:${pad(
        date.getUTCSeconds()
    )}`;
}

export function getTimezoneName(utcDateString) {
    const date = new Date(utcDateString);
    const timezoneName = new Intl.DateTimeFormat('en-US', { timeZoneName: 'short' }).format(date).split(', ')[1];
    return timezoneName;
}
