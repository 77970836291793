import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import GridLoader from 'react-spinners/GridLoader';
import Alert from "react-bootstrap/Alert";
import Slider from "react-slick";
import { FaNewspaper, FaRobot, FaLanguage, FaChartLine, FaSearch, FaPlug } from 'react-icons/fa';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../GlobalStyles.css';

/** Custom hook to check for mobile view */
const useIsMobile = (breakpoint = 768) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= breakpoint);
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= breakpoint);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [breakpoint]);
  return isMobile;
};

/** Simple spacer component for vertical spacing */
const Spacer = ({ height = "1rem" }) => <div style={{ height }} />;

const Home = () => {
  const isMobile = useIsMobile();
  const imageContainerRef = useRef(null);
  const [loaderSize, setLoaderSize] = useState(0);

  // Recalculate loader size on mount and on window resize
  useEffect(() => {
    const updateLoaderSize = () => {
      if (imageContainerRef.current) {
        const containerWidth = imageContainerRef.current.offsetWidth;
        setLoaderSize(containerWidth * 0.17);
      }
    };
    updateLoaderSize();
    window.addEventListener('resize', updateLoaderSize);
    return () => window.removeEventListener('resize', updateLoaderSize);
  }, []);

  const heroTextStyle = {
    fontSize: isMobile ? "3rem" : "4.5rem",
    fontWeight: 500,
    lineHeight: 1
  };

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 2000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    arrows: false,
    responsive: [
      {
        breakpoint: 768,
        settings: { slidesToShow: 2 }
      },
      {
        breakpoint: 480,
        settings: { slidesToShow: 1 }
      }
    ]
  };

  // Logos for the Trusted By slider
  const trustedByLogos = [
    { id: 1, url: 'https://i.imgur.com/rfsB5bH.png' },
    { id: 2, url: 'https://i.imgur.com/HZQHZhY.png' },
    { id: 3, url: 'https://i.imgur.com/XBcAV8Y.png' },
    { id: 4, url: 'https://i.imgur.com/xUA5y0c.png' },
    { id: 5, url: 'https://i.imgur.com/cuNOqND.png' }
  ];

  // On mobile, do not add extra margin class ("mb-2")
  const testimonialClass = "account-option shadow-border hover-lift" + (isMobile ? "" : " mb-2");

  return (
    <div>
      {/* Alert Section */}
      <div>
        <a
          href="https://verbaai.org/globalpulse"
          rel="noopener noreferrer"
          style={{ textDecoration: 'none' }}
        >
          <Alert
            style={{
              backgroundColor: '#222222',
              color: '#F8F9FA',
              padding: '10px 20px'
            }}
            className="text-center"
          >
            VerbaAI Global Pulse: Read real-time briefings on top news from around the world.
          </Alert>
        </a>
      </div>

      {/* Main Content Section */}
      <div className="home-container">
        <div className="content-flex-container">
          <div className="text-content">
            <div style={heroTextStyle}>
              Know what's happening,<br /> when it's happening.
            </div>
            <Spacer height="1rem" />
            <p>
              Monitor by-the-minute reporting from across the world, all in one place.
              Get critical insights and analysis in seconds.
            </p>
            <div>
              <Link to="/international" className="btn-link">
                <button className="btn-custom btn-charcoal-gray mr-1 mb-2">Get Started</button>
              </Link>
              <Link to="/about" className="btn-link">
                <button className="btn-custom btn-mid-gray">Learn More</button>
              </Link>
            </div>
          </div>
          <div className="image-content" ref={imageContainerRef}>
            <div className="loader-container hide-on-mobile">
              <GridLoader color="#000000" speedMultiplier={0.1} size={loaderSize} />
            </div>
          </div>
        </div>
      </div>

      {/* User Testimonials Section */}
      <Spacer height="0.75rem" />
      <div className="base-container">
        <h1>What Our Users Say</h1>
      </div>
      <div className="home-container">
        <div className="plans-container">
          <div className={testimonialClass}>
            <h4>
              "VerbaAI is my go-to source for world news. It has the most comprehensive coverage out there."
            </h4>
            <h4><i>Federal Consultant</i></h4>
          </div>
          <div className={testimonialClass}>
            <h4>
              "VerbaAI is a game-changer for monitoring rapidly developing situations."
            </h4>
            <h4><i>Foreign Service Officer</i></h4>
          </div>
          <div className={testimonialClass}>
            <h4>
              "VerbaAI helps me understand perspectives from foreign countries, even if I don't speak the language."
            </h4>
            <br />
            <h4><i>Fulbright Scholar</i></h4>
          </div>
        </div>
      </div>

      {/* Trusted By Section */}
      <Spacer height="1rem" />
      <div className="base-container">
        <h1>Trusted By</h1>
      </div>
      <div className="base-container">
        <div className="slider-container">
          <Slider {...sliderSettings}>
            {trustedByLogos.map((logo) => (
              <div key={logo.id}>
                <img
                  src={logo.url}
                  alt={`Trusted Logo ${logo.id}`}
                  className="trusted-logo"
                />
              </div>
            ))}
          </Slider>
        </div>
      </div>

      {/* Key Features Section */}
      <Spacer height="1rem" />
      <div className="base-container">
        <h1>Key Features</h1>
      </div>
      <div className="home-container">
        <div className="grid-container-features">
          <div className="account-option mb-2 shadow-border feature-item hover-lift" style={{ textAlign: 'center' }}>
            <FaNewspaper size={40} color="#222222" style={{ marginBottom: '10px' }} />
            <h2>Real-Time Data</h2>
            <h4>
              Monitor and analyze by-the-minute media data from around the world.
            </h4>
          </div>
          <div className="account-option mb-2 shadow-border feature-item hover-lift" style={{ textAlign: 'center' }}>
            <FaRobot size={40} color="#222222" style={{ marginBottom: '10px' }} />
            <h2>AI-Powered Summaries</h2>
            <h4>
              Get key insights on critical developments in five bullet points.
            </h4>
          </div>
          <div className="account-option mb-2 shadow-border feature-item hover-lift" style={{ textAlign: 'center' }}>
            <FaLanguage size={40} color="#222222" style={{ marginBottom: '10px' }} />
            <h2>Multilingual Translation</h2>
            <h4>
              Gain critical insights conveyed in foreign languages.
            </h4>
          </div>
          <div className="account-option mb-2 shadow-border feature-item hover-lift" style={{ textAlign: 'center' }}>
            <FaChartLine size={40} color="#222222" style={{ marginBottom: '10px' }} />
            <h2>Narrative Intelligence</h2>
            <h4>
              Track narratives across their entire lifecycle: from inception, to transformation, to conclusion.
            </h4>
          </div>
          <div className="account-option mb-2 shadow-border feature-item hover-lift" style={{ textAlign: 'center' }}>
            <FaSearch size={40} color="#222222" style={{ marginBottom: '10px' }} />
            <h2>Comprehensive Search</h2>
            <h4>
              Use natural language to query our exhaustive database with precision.
            </h4>
          </div>
          <div className="account-option mb-2 shadow-border feature-item hover-lift" style={{ textAlign: 'center' }}>
            <FaPlug size={40} color="#222222" style={{ marginBottom: '10px' }} />
            <h2>Plug-and-Play Data Integration</h2>
            <h4>
              VerbaAI can extract key insights from any media data stream, including news and social media.
            </h4>
          </div>
        </div>
      </div>

      {/* Backed By Section */}
      <Spacer height="1rem" />
      <div className="base-container">
        <h1>Backed By</h1>
      </div>
      <Spacer height="0.75rem" />
      <div className="grid-container">
        <div>
          <a href="https://www.virginiaipc.org/" target="_blank" rel="noopener noreferrer" className="btn-link">
            <img
              src="https://i.imgur.com/LaXN2E2_d.webp?maxwidth=1520&fidelity=grand"
              style={{ height: 'auto', maxWidth: '60%' }}
              alt="VirginiaIPC Logo"
            />
          </a>
        </div>
        <div>
          <a href="https://www.lighthouselabsrva.org/" target="_blank" rel="noopener noreferrer" className="btn-link">
            <img
              src="https://i.imgur.com/qqxLmnR_d.webp?maxwidth=1520&fidelity=grand"
              style={{ height: 'auto', maxWidth: '60%' }}
              alt="Lighthouse Labs Logo"
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Home;
