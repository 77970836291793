import '../GlobalStyles.css'; // Import the CSS file
import React, { useState, useEffect, useContext } from "react";
import "../GlobalStyles.css";
import DataTable from '../search/DataTable.js';
import axios from 'axios';
import { getDatePublished, getTimePublished } from "../../utils.js/dateHelpers"


const LiveNewsFeed = () => {
    const filters = { "tracker_type": ["international politics"], "query": "" };
    const [tableData, setTableData] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.post('https://fopotracking.ue.r.appspot.com/search', filters);
                let records = [];

                response['data'].forEach(item => {
                    const { date_published, link, word_count, title, source_url, country, language, website_name, country_rank, site_rank, topics, locations, organizations, persons, summary } = item['source'];
                    const uniqueId = item['id'];
                    let date = new Date(item['source']['date_published']);

                    records.push({
                        "article_id": uniqueId,
                        "date_time_published_utc": item['source']['date_published'].replace(" ", "T") + "+00:00",
                        "date_published": getDatePublished(item['source']['date_published'].replace(" ", "T") + "+00:00"),
                        "time_published": getTimePublished(item['source']['date_published'].replace(" ", "T") + "+00:00"),
                        "timezone_name": new Intl.DateTimeFormat('en-US', { timeZoneName: 'short' }).format(date).split(', ')[1],
                        "link": link,
                        "word_count": word_count,
                        "title": title,
                        "source_url": source_url,
                        "country": country,
                        "language": language,
                        "source": website_name,
                        "country_rank": country_rank,
                        "site_rank": site_rank,
                        "topics": topics,
                        "locations": locations,
                        "organizations": organizations,
                        "persons": persons,
                        "summary": summary,
                    });
                });
                setTableData(records);
                setCurrentIndex(0); // Reset index to 0 when new data is fetched
            }
            catch (error) {
                console.error('Error fetching latest data:', error);
            }
        };

        fetchData(); // Fetch data initially
        const fetchInterval = setInterval(fetchData, 600000); // Call fetchData every 10 minutes
        const displayInterval = setInterval(() => {
            setCurrentIndex(prevIndex => (prevIndex + 1) % tableData.length); // Cycle through records every 30 seconds
        }, 45000);

        return () => {
            clearInterval(fetchInterval);
            clearInterval(displayInterval); // Cleanup both intervals on component unmount
        };
    }, [tableData.length]); // Rerun useEffect only when tableData.length changes

    return (
        <div>
            <h1 style={{ textAlign: 'center' }}>News from Around the World</h1>
            <br/>
            <DataTable data={tableData.slice(currentIndex, currentIndex + 1)} showIndex={false} showShareIcons={false}/>
        </div>
    );
};

export default LiveNewsFeed;