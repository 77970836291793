import '../GlobalStyles.css'; // Import the CSS file
import React, { useState, useEffect, useContext } from "react";
import "../GlobalStyles.css";
import SearchableTable from '../search/SearchableTable.js';
import { Tab, Tabs } from 'react-bootstrap';
import SearchableCluster from '../search/SearchableCluster.js';


const HobokenNews = () => {
    return (
        <div>
            <h1 style={{ textAlign: 'center' }}>Hoboken News Tracker</h1>
            <Tabs defaultActiveKey="hoboken" id="uncontrolled-tab-example" className="border-3">
                <Tab eventKey="hoboken" title="Hoboken News">
                    <SearchableTable
                        baseRequest={{
                            "tracker_type": ["hoboken news"]
                        }}
                        excludePast24Hours={true}
                    />
                </Tab>
                <Tab eventKey="ajmani" title="Dini Ajmani">
                    <SearchableTable
                        baseRequest={{
                            "tracker_type": ["hoboken news"],
                            "persons" : ["Dini Ajmani"]
                        }}
                        excludePast24Hours={true}
                    />
                </Tab>
                <Tab eventKey="jabbour" title="Emily Jabbour">
                    <SearchableTable
                        baseRequest={{
                            "tracker_type": ["hoboken news"],
                            "persons" : ["Emily Jabbour"]
                        }}
                        excludePast24Hours={true}
                    />
                </Tab>
                <Tab eventKey="fisher" title="Tiffanie Fisher">
                    <SearchableTable
                        baseRequest={{
                            "tracker_type": ["hoboken news"],
                            "persons" : ["Tiffanie Fisher"]
                        }}
                        excludePast24Hours={true}
                    />
                </Tab>
                <Tab eventKey="ramos" title="Ruban Ramos Jr.">
                    <SearchableCluster
                        baseRequest={{
                            "tracker_type": ["hoboken news"],
                            "persons" : ["Ruban Ramos Jr."]
                        }}
                        excludePast24Hours={true}
                    />
                </Tab>
                <Tab eventKey="russo" title="Michael Russo">
                    <SearchableCluster
                        baseRequest={{
                            "tracker_type": ["hoboken news"],
                            "persons" : ["Michael Russo"]
                        }}
                        excludePast24Hours={true}
                    />
                </Tab>
                <Tab eventKey="bhalla" title="Ravi Bhalla">
                    <SearchableCluster
                        baseRequest={{
                            "tracker_type": ["hoboken news"],
                            "persons" : ["Ravi Bhalla"]
                        }}
                        excludePast24Hours={true}
                    />
                </Tab>
                <Tab eventKey="presinzano" title="Paul Presinzano">
                    <SearchableCluster
                        baseRequest={{
                            "tracker_type": ["hoboken news"],
                            "persons" : ["Paul Presinzano"]
                        }}
                        excludePast24Hours={true}
                    />
                </Tab>
                <Tab eventKey="cohen" title="Phil Cohen">
                    <SearchableCluster
                        baseRequest={{
                            "tracker_type": ["hoboken news"],
                            "persons" : ["Phil Cohen"]
                        }}
                        excludePast24Hours={true}
                    />
                </Tab>
                <Tab eventKey="quintero" title="Joseph Quintero">
                    <SearchableCluster
                        baseRequest={{
                            "tracker_type": ["hoboken news"],
                            "persons" : ["Joseph Quintero"]
                        }}
                        excludePast24Hours={true}
                    />
                </Tab>
                <Tab eventKey="doyle" title="James Doyle">
                    <SearchableCluster
                        baseRequest={{
                            "tracker_type": ["hoboken news"],
                            "persons" : ["James Doyle"]
                        }}
                        excludePast24Hours={true}
                    />
                </Tab>
            </Tabs>
        </div>
    );
};

export default HobokenNews;
