import '../GlobalStyles.css'; // Import the CSS file
import React, { useState, useEffect, useContext } from "react";
import "../GlobalStyles.css";
import SearchableTable from '../search/SearchableTable.js';
import DataTableLWV from '../search/DataTableLWV.js';
import { useUser } from "../../contexts/UserContext.js";
import Alert from "react-bootstrap/Alert";
import { Tab, Tabs, Badge } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';
import YouTube from 'react-youtube';


const ElectionFactsDD = () => {

    const videoId = 'G5fEmozCc2w';

    const getVideoOpts = (start) => ({
        // height: `${window.innerHeight * 0.5}px`,
        height: isMobile ? `${window.innerHeight * 0.3}px` : `${window.innerHeight * 0.7}px`,
        width: isMobile ? '100%' : '70%',
        playerVars: {
            autoplay: 0,
            start: start,
        },
    });

    const rumorFilters = {
        "state": "VA"
    }

    const filters = {
        "sources": [
            "Snopes - snopes.com",
            "FactCheck.org - factcheck.org",
            "PolitiFact - politifact.com",
            "Lead Stories - leadstories.com"
        ],
        "topics": ["Politics"],
        "locations": ["United States"]
    };

    const { user } = useUser();

    // Check if isMobile
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    useEffect(() => {
        function handleResize() {
            setIsMobile(window.innerWidth <= 768);
        }
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const customSourceOptions = [
        { label: 'FactCheck - factcheck.org', value: 'factcheck.org' },
        { label: 'Lead Stories - leadstories.com', value: 'leadstories.com' },
        { label: 'PolitiFact - politifact.com', value: 'politifact.com' },
        { label: 'Snopes - snopes.com', value: 'snopes.com' },
    ];


    const [rumorRadarData, setRumorRadarData] = useState([]); //tableData will always be displayed in the data table; setTableData() needs to be called by handleSearchClick

    useEffect(() => {
        fetchRumorRadarData();
    }, []); // This will call fetchData on component mount


    const fetchRumorRadarData = async () => {
        try {

            // console.log("L84 filters are", filters);

            const response = await axios.post('https://fopotracking.ue.r.appspot.com/lwv-search', rumorFilters);
            // const response = await axios.post('http://localhost:5000/lwv-search', filters);

            let records = [];

            response['data'].forEach(item => {
                const { date_published, link, word_count, title, source_url, country, language, website_name, country_rank, site_rank, topics, locations, organizations, persons, summary } = item['source'];
                const uniqueId = item['id']; // Adjust this according to your response structure

                records.push({
                    "date_published": item['source']['date_published'],
                    "misleading_claim": item['source']['misleading_claim'],
                    "reference": item['source']['reference'],
                    "fact_check": item['source']['fact_check']
                });
            });
            setRumorRadarData(records);
            // console.log("records are", records);
        }
        catch (error) {
            console.error('Error fetching latest data:', error);
        }
    };

    const renderMobilePage = () => (
        <div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <img src="/LWVVA_Logo.png" alt="LWVVA_Logo" style={{ maxWidth: '90%', height: 'auto' }} />
            </div>
            <br />
            <h1 style={{ textAlign: 'center' }}>2024 U.S. Elections: The Facts</h1>
            <h2 style={{ textAlign: 'center' }}>Curated from Leading Fact-Checking Orgs</h2>
            {!user && (
                <div>
                    <Alert style={{ backgroundColor: '#222222', color: '#F8F9FA', padding: '10px 20px' }} className="text-center">
                        Sign in to VerbaAI to access additional features, including bookmarking
                        speeches and creating custom trackers.
                    </Alert>
                </div>
            )}
            <Tabs defaultActiveKey="fact-check-feed" id="uncontrolled-tab-example" className="border-3">
                <Tab eventKey="fact-check-feed" title="Fact Check Feed">
                    <SearchableTable electionFactsTracker={true} baseRequest={filters} showCluster={false} countrySearch={false} countryColumn={false} languageSearch={false} languageColumn={false} sourceOptions={customSourceOptions} excludePast24Hours={true} preset_query={'election'} />
                </Tab>
                <Tab eventKey="rumor-radar" title="Rumor Radar">
                    <div className="searchable-container">
                        <div className="data-table">
                            <DataTableLWV data={rumorRadarData} />
                        </div>
                    </div>
                </Tab>
                <Tab eventKey="video-guide" title="Video Guide">
                    <br />
                    <h4>
                        <YouTube style={{ textAlign: 'center' }} videoId={videoId} opts={getVideoOpts(0)} />
                    </h4>
                    <br />
                </Tab>
                <Tab eventKey="about" title="About">
                    <br />
                    <h4>
                        The spread of false information during elections poses a critical threat to U.S. democracy. These falsehoods can rapidly proliferate online, misleading voters about candidates and policies, distorting democratic dialogue, and eroding the public's trust in the very institutions meant to safeguard their voices.
                        <br /><br />
                        VerbaAI has partnered with the <Link to="https://my.lwv.org/virginia">League of Women Voters of Virginia</Link> to launch Election Facts, a non-partisan, one-stop-shop for fact-checking U.S. election news. Election Facts has two key components.
                        <br /><br />
                        <b><i>Fact Check Feed:</i></b> We monitor relevant reports from leading fact-checking organizations that investigate the accuracy of political stories. The work of these fact-checking organizations is crucial, and by consolidating their research into one place, we hope to make fact-checking political news easier than ever.
                        <br /><br />
                        <b><i>Rumor Radar:</i></b> We publish fact-checks by members of the LWV-Virginia on misleading narratives about the elections.
                        <br /><br />
                        Election Facts features real-time news monitoring, AI summaries for key insights, and comprehensive search filters.
                        Check out our <Link to="/userguide">User Guide</Link> for more information on each of these features. And, check out our other <Link to="/issue-trackers">Issue Trackers</Link> to see how VerbaAI is making it easier than ever to stay informed online.
                        <br /><br /><br />
                    </h4>
                    <br />
                </Tab>
            </Tabs>
        </div>
    );


    const renderDesktopPage = () => (
        <div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ flex: 1 }}>
                </div>
                <div style={{ flex: 2.5, textAlign: 'center' }}>
                    <h1>2024 U.S. Election News: The Facts</h1>
                    <h2>Curated from Leading Fact-Checking Orgs</h2>
                </div>
                <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <img src="/LWVVA_Logo.png" alt="LWVVA_Logo" style={{ maxWidth: '100%', height: 'auto' }} />
                </div>
            </div>
            <Tabs defaultActiveKey="fact-check-feed" id="uncontrolled-tab-example" className="border-3">
                <Tab eventKey="fact-check-feed" title="Fact Check Feed">
                    <SearchableTable electionFactsTracker={true} baseRequest={filters} showCluster={false} countrySearch={false} countryColumn={false} languageSearch={false} languageColumn={false} sourceOptions={customSourceOptions} excludePast24Hours={true} preset_query={'election'} />
                </Tab>
                <Tab eventKey="rumor-radar" title="Rumor Radar">
                    <DataTableLWV data={rumorRadarData} />
                </Tab>
                <Tab eventKey="video-guide" title="Video Guide">
                    <br />
                    <h4>
                        <YouTube style={{ textAlign: 'center' }} videoId={videoId} opts={getVideoOpts(0)} />
                    </h4>
                    <br />
                </Tab>
                <Tab eventKey="about" title="About">
                    <br />
                    <h4>
                        The spread of false information during elections poses a critical threat to U.S. democracy. These falsehoods can rapidly proliferate online, misleading voters about candidates and policies, distorting democratic dialogue, and eroding the public's trust in the very institutions meant to safeguard their voices.
                        <br /><br />
                        VerbaAI has partnered with the <Link to="https://my.lwv.org/virginia">League of Women Voters of Virginia</Link> to launch Election Facts, a non-partisan, a one-stop-shop for fact-checking U.S. election news. Election Facts has two key components.
                        <br /><br />
                        <b><i>Fact Check Feed:</i></b> We monitor relevant reports from leading fact-checking organizations that investigate the accuracy of political stories. The work of these fact-checking organizations is crucial, and by consolidating their research into one place, we hope to make fact-checking political news easier than ever.
                        <br /><br />
                        <b><i>Rumor Radar:</i></b> We publish fact-checks by members of the LWV-Virginia on misleading narratives about the elections.
                        <br /><br />
                        Election Facts features real-time news monitoring, AI summaries for key insights, and comprehensive search filters.
                        Check out our <Link to="/userguide">User Guide</Link> for more information on each of these features. And, check out our other <Link to="/issue-trackers">Issue Trackers</Link> to see how VerbaAI is making it easier than ever to stay informed online.
                    </h4>
                    <br />
                </Tab>
            </Tabs>
        </div>
    );

    return (
        <div>
            {isMobile ? renderMobilePage() : renderDesktopPage()}
        </div>
    );

};

export default ElectionFactsDD;
