import '../GlobalStyles.css'; // Import the CSS file
import React, { useState, useEffect, useContext } from "react";
import "../GlobalStyles.css";
import SearchableTable from '../search/SearchableTable.js';
import { useUser } from "../../contexts/UserContext.js";
import Alert from "react-bootstrap/Alert";
import { Tab, Tabs } from 'react-bootstrap';
import SearchableCluster from '../search/SearchableCluster.js';


const IPTracker = () => {
    const { user } = useUser();
    const filters = { "tracker_type": ["international politics"] };

    return (
        <div>
            <h1 style={{ textAlign: 'center' }}>International Politics Tracker</h1>
            {!user && (
                <Alert style={{ backgroundColor: '#222222', color: '#F8F9FA', padding: '10px 20px' }} className="text-center">
                    Sign in to VerbaAI to access additional features, including bookmarking
                    speeches and creating custom trackers.
                </Alert>
            )}
            <Tabs defaultActiveKey="stories" id="uncontrolled-tab-example" className="border-3">
                <Tab eventKey="stories" title="Breaking News">
                    <SearchableCluster selectedSortType="Last Updated" />
                </Tab>
                <Tab eventKey="articles" title="Article Feed">
                    <SearchableTable
                        showCharged={true}
                        baseRequest={filters}
                        trackerType={["international politics"]}
                    />
                </Tab>
            </Tabs>
        </div>
    );
};

export default IPTracker;
