import '../GlobalStyles.css'; // Import the CSS file
import React, { useState, useEffect, useContext } from "react";
import "../GlobalStyles.css";
import SearchableTable from '../search/SearchableTable.js';
import { useUser } from "../../contexts/UserContext.js";
import Alert from "react-bootstrap/Alert";
import { Tab, Tabs } from 'react-bootstrap';
import SearchableCluster from '../search/SearchableCluster.js';


const ChinaNews = () => {
    const { user } = useUser();
    const generalFilters = {
        countries: ["China"]
    };

    return (
        <div>
            <h1 style={{ textAlign: 'center' }}>China News Tracker</h1>
            <Tabs defaultActiveKey="global-stories" id="uncontrolled-tab-example" className="border-3">
                <Tab eventKey="global-stories" title="Global Narratives">
                    <div className="searchable-container">
                        <SearchableCluster
                            baseRequest={generalFilters}
                            selectedSortType="Last Updated"
                        />
                    </div>
                </Tab>
                {/* TODO: Add in china exclusive cluster data here */}
                <Tab eventKey="domestic-stories" title="China Narratives">
                    <div className="searchable-container">
                        <SearchableCluster
                            selectedSortType="Last Updated"
                            trackerType={["china news"]}
                            chinaCluster={true}
                        />
                    </div>
                </Tab>
                <Tab eventKey="articles" title="Article Feed">
                    <SearchableTable
                        showCharged={true}
                        trackerType={["china news"]}
                        countrySearch={false}
                        chinaCluster={true}
                    />
                </Tab>
                <Tab eventKey="about" title="About">
                    <br />
                    <h4>
                        This tracker monitors emerging news on Chinese politics and security.
                        <br /><br />
                        <b><i>Global Narratives:</i></b> Insights on China from both Chinese and foreign news sources.
                        <br /><br />
                        <b><i>China Narratives:</i></b> Insights exclusively from 3,000 Chinese news sources.
                        <br /><br />
                        <b><i>Article Feed:</i></b> Articles on politics and security from 3,000 Chinese news sources.
                        <br /><br /><br />
                    </h4>
                    <br />
                </Tab>
            </Tabs>
        </div>
    );
};

export default ChinaNews;
