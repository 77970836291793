import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "../GlobalStyles.css";
import SearchableTable from "../search/SearchableTable.js";
import SearchableCluster from "../search/SearchableCluster.js";
import { useUser } from "../../contexts/UserContext.js";
import Alert from "react-bootstrap/Alert";
import { Tab, Tabs, Badge } from "react-bootstrap";
import { Link } from 'react-router-dom';


const getIssueDetails = (issue) => {
    const details = {
        climate_change: {
            title: "Breaking News: Climate Change",
            intro: "Explore in-depth coverage on climate change. Our monitoring from news outlets around the world offers a comprehensive view of climate policy, environmental impacts, scientific research, and global initiatives.",
            keywords: [],
            filters: { tracker_type: ["international politics"], utah: false },
            preset_query: "climate change",
            country_search: true,
            utah: false
        },
        us_public_lands: {
            title: "Breaking News: U.S. Public Lands",
            intro: "This tracker monitors the latest news stories regarding U.S. public lands.",
            keywords: [],
            filters: { countries: ["United States"], tracker_type: ["utah news"], utah: true },
            preset_query: "United States public lands",
            country_search: false,
            utah: true
        },
        us_health_care: {
            title: "Breaking News: U.S. Health Care",
            intro: "This tracker monitors the latest news stories regarding U.S. health care.",
            keywords: [],
            filters: {  countries: ["United States"], tracker_type: ["utah news"], utah: true, topics: ["Health"] },
            preset_query: "United States health care",
            country_search: false,
            utah: true
        },
        us_public_education: {
            title: "Breaking News: U.S. Public Education",
            intro: "This tracker monitors the latest news stories regarding U.S. public education.",
            keywords: [],
            filters: { countries: ["United States"], tracker_type: ["utah news"], utah: true, topics: ["Education"] },
            preset_query: "United States public education",
            country_search: false,
            utah: true
        },
        us_government: {
            title: "Breaking News: U.S. Government",
            intro: "This tracker monitors the latest news stories regarding U.S. election administration.",
            keywords: [],
            filters: { countries: ["United States"],  tracker_type: ["utah news"], utah: true },
            preset_query: "United States election administration",
            country_search: false,
            utah: true
        },
    };

    return details[issue] || null;
};

const LWVUTIssuePage = () => {
    const { issue } = useParams();
    const issueDetails = getIssueDetails(issue);
    const { user } = useUser();

    // Handle responsive design
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 768);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    if (!issueDetails) {
        return <h1 style={{ textAlign: "center" }}>Issue Not Found</h1>;
    }

    // Mobile Page Rendering
    const renderMobilePage = () => (
        <div>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                <img src="/LWVUT_Logo.png" alt="LWVUT_Logo" style={{ maxWidth: "90%", height: "auto" }} />
            </div>
            <h1 style={{ textAlign: "center" }}>{issueDetails.title}</h1>

            <Tabs defaultActiveKey="narratives" id="uncontrolled-tab-example" className="border-3">
                <Tab eventKey="narratives" title="Narratives">
                    <SearchableCluster
                        key={issueDetails.preset_query}
                        baseRequest={issueDetails.filters}
                        preset_query={issueDetails.preset_query}
                        selectedSortType="Relevance"
                        countrySearch={issueDetails.country_search}
                        utahCluster={issueDetails.utah}
                    />
                </Tab>
                <Tab eventKey="article-feed" title="Article Feed">
                    <SearchableTable
                        showCharged={true}
                        key={issueDetails.title}
                        baseRequest={issueDetails.filters}
                        preset_query={issueDetails.preset_query}
                        trackerType={["international politics"]}
                        countrySearch={issueDetails.country_search}
                    />
                </Tab>
                <Tab eventKey="about" title="About">
                    <br />
                    <h4 style={{ textAlign: "center" }}>{issueDetails.intro}</h4>
                </Tab>
            </Tabs>
        </div>
    );

    // Desktop Page Rendering
    const renderDesktopPage = () => (
        <div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Link to="/lwvut" className="btn-link">
                        <img src="/LWVUT_Logo.png" alt="LWVUT_Logo" style={{ maxWidth: '100%', height: 'auto' }} />
                    </Link>
                </div>
                <div style={{ flex: 2, textAlign: 'center' }}>
                    <h1>{issueDetails.title}</h1>
                    <h2>Comprehensive Media Coverage</h2>
                </div>
                <div style={{ flex: 1 }}>
                </div>
            </div>
            <Tabs defaultActiveKey="narratives" id="uncontrolled-tab-example" className="border-3">
                <Tab eventKey="narratives" title="Narratives">
                    <SearchableCluster
                        key={issueDetails.preset_query}
                        baseRequest={issueDetails.filters}
                        preset_query={issueDetails.preset_query}
                        selectedSortType="Relevance"
                        countrySearch={issueDetails.country_search}
                        utahCluster={issueDetails.utah}
                    />
                </Tab>
                <Tab eventKey="article-feed" title="Article Feed">
                    <SearchableTable
                        showCharged={true}
                        key={issueDetails.title}
                        baseRequest={issueDetails.filters}
                        preset_query={issueDetails.preset_query}
                        trackerType={["international politics"]}
                        countrySearch={issueDetails.country_search}
                    />
                </Tab>
                <Tab eventKey="about" title="About">
                    <br />
                    <h4 style={{ textAlign: "center" }}>{issueDetails.intro}</h4>
                </Tab>
            </Tabs>
        </div>
    );

    return <div>{isMobile ? renderMobilePage() : renderDesktopPage()}</div>;
};

export default LWVUTIssuePage;
