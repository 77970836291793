import React from 'react';
import { useParams } from 'react-router-dom';
import SearchableTable from '../search/SearchableTable.js';
import '../GlobalStyles.css';
import '../search/Search.css';

const ShareArticle = () => {
  // Get the first segment as "index" and the rest of the path (if any) as the article ID.
  const { index, '*': rest } = useParams();
  
  // Determine the article ID from the rest of the path.
  const articleId = rest && rest !== '' ? rest : null;

  // Initialize all cluster flags as false.
  const clusterFlags = {
    fringeCluster: false,
    chinaCluster: false,
    turkeyCluster: false,
    iranCluster: false,
    russiaCluster: false,
    usaCluster: false,
    hamasCluster: false,
    hezbollahCluster: false,
    isisCluster: false,
    bokoHaramCluster: false,
    alQaedaCluster: false,
    spaceForceCluster: false,
    utahCluster: false,
  };

  // Based on the "index" value, update the corresponding cluster flag.
  if (index) {
    switch (index.toLowerCase()) {
      case 'cn':
        clusterFlags.chinaCluster = true;
        break;
      case 'ir':
        clusterFlags.iranCluster = true;
        break;
      case 'tr':
        clusterFlags.turkeyCluster = true;
        break;
      case 'ru':
        clusterFlags.russiaCluster = true;
        break;
      case 'us':
        clusterFlags.usaCluster = true;
        break;
      case 'hamas':
        clusterFlags.hamasCluster = true;
        break;
      case 'hezbollah':
        clusterFlags.hezbollahCluster = true;
        break;
      case 'isis':
        clusterFlags.isisCluster = true;
        break;
      case 'bokoharam':
      case 'boko':
        clusterFlags.bokoHaramCluster = true;
        break;
      case 'alqaeda':
        clusterFlags.alQaedaCluster = true;
        break;
      case 'spaceforce':
      case 'sf':
        clusterFlags.spaceForceCluster = true;
        break;
      case 'ut':
      case 'utah':
        clusterFlags.utahCluster = true;
        break;
      default:
        break;
    }
  }

  // Build the initial filters using the article ID (if available)
  const initialFilters = {
    article_id: articleId ? [articleId] : [],
    date_published: ["01/01/2000", "01/01/3000"]
  };

  return (
    <div className="data-table">
      <SearchableTable
        showCharged={true}
        baseRequest={initialFilters}
        showSideBar={false}
        shareArticle={true}
        fringeCluster={clusterFlags.fringeCluster}
        chinaCluster={clusterFlags.chinaCluster}
        turkeyCluster={clusterFlags.turkeyCluster}
        iranCluster={clusterFlags.iranCluster}
        russiaCluster={clusterFlags.russiaCluster}
        usaCluster={clusterFlags.usaCluster}
        hamasCluster={clusterFlags.hamasCluster}
        hezbollahCluster={clusterFlags.hezbollahCluster}
        isisCluster={clusterFlags.isisCluster}
        bokoHaramCluster={clusterFlags.bokoHaramCluster}
        alQaedaCluster={clusterFlags.alQaedaCluster}
        spaceForceCluster={clusterFlags.spaceForceCluster}
        utahCluster={clusterFlags.utahCluster}
      />
    </div>
  );
};

export default ShareArticle;
