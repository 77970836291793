import React from 'react';
import '../GlobalStyles.css';

const About = () => {
  return (
    <div className="base-container">
      <div className="text-content">
        <h1 style={{ textAlign: 'center' }}>About VerbaAI</h1>
        <br />
        <h3>Our Mission: To develop software that strengthens our information environment.</h3>
        <p>We've spent years <a href="https://www.diplomaticourier.com/people/aaraj-vij" style={{ textDecoration: 'underline', color: 'inherit' }}> researching</a> our information environment at <a href="https://www.wm.edu/offices/global-research/" style={{ textDecoration: 'underline', color: 'inherit' }}>William & Mary's Global Research Institute</a>. Now, we're building tools to strengthen it.
          We're backed by <a href="https://www.lighthouselabsrva.org/post/announcing-batch-17-of-lighthouse-labs" style={{ textDecoration: 'underline', color: 'inherit' }}> Lighthouse Labs RVA</a> and the <a href="https://www.virginiaipc.org/about-us/" style={{ textDecoration: 'underline', color: 'inherit' }}> Virginia IPC</a>.</p>
        <br />
        <h3>Our Vision: To make every person more informed.</h3>
        <p>Everyone benefits from informed decision making. That's why we prioritize:</p>
        <ul>
          <li><span style={{ fontWeight: 500 }}>Cutting-Edge AI:</span> We leverage the latest breakthroughs in AI to give you the critical insights you need, when you need them.</li>
          <li><span style={{ fontWeight: 500 }}>User-Centric Design:</span> Our software is intuitive and easy to use out of the box, regardless of your technical background.</li>
          <li><span style={{ fontWeight: 500 }}>Customized Intelligence:</span> We build tools for a variety of professionals, and tailor them to your specific needs.</li>
        </ul>

        <br />
        <h3>VerbaAI: Redefining Intelligence</h3>
        <p>Whether you’re a policymaker crafting legislation, a defense analyst assessing risks, or an academic researching global trends, VerbaAI is designed to provide deep, real-time insights into the information ecosystem. Our platform dynamically tracks and analyzes thousands of news sources and social media streams to uncover emerging narratives, malign influence operations, and critical global developments.</p>
        <br />
        <h3>Contact Us</h3>
        <p>Accelerate your research, surface hidden patterns, and identify emerging trends with unparalleled speed. Contact us at (info@verbaai.org).</p>
      </div>
    </div>
  );
};

export default About;

