import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../GlobalStyles.css';
import { HiMiniBarsArrowUp, HiMiniBarsArrowDown } from 'react-icons/hi2';

// Helper function to format each card's timestamp as "MM-DD-YYYY HH:mm:ss EST"
const formatTimestamp = (isoString) => {
  const date = new Date(isoString);
  // Format the date part as MM-DD-YYYY
  const datePart = date.toLocaleDateString("en-US", {
    timeZone: "America/New_York",
    month: "2-digit",
    day: "2-digit",
    year: "numeric"
  }).replace(/\//g, "-");

  // Format the time part as HH:mm:ss in 24-hour time
  const timePart = date.toLocaleTimeString("en-US", {
    timeZone: "America/New_York",
    hour12: false,
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit"
  });

  return `${datePart} ${timePart} EST`;
};

/**
 * This function determines the header timestamp as follows:
 * 1. Finds the most recent timestamp among all entries (in UTC).
 * 2. Converts it to America/New_York local time (automatically handling DST).
 * 3. If any seconds or milliseconds exist, it rounds up to the next minute.
 * 4. Formats as "MM-DD-YYYY HH:mm EST" (always labeled "EST").
 */
const getHeaderTimestamp = (data) => {
  if (!data || data.length === 0) return 'Recent History Feed';

  // 1. Find the most recent timestamp in UTC
  let maxDate = new Date(data[0].timestamp);
  data.forEach(entry => {
    const current = new Date(entry.timestamp);
    if (current > maxDate) maxDate = current;
  });

  // 2. Convert maxDate to local time in America/New_York as a string
  const nyString = maxDate.toLocaleString("en-US", {
    timeZone: "America/New_York",
    hour12: false
  });
  // nyString is something like "MM/DD/YYYY, HH:mm:ss"

  // 3. Parse that local string back into a Date object so we can manipulate it
  const [datePart, timePart] = nyString.split(", ");
  const [month, day, year] = datePart.split("/");
  const [hour, minute, second] = timePart.split(":");

  let nyDate = new Date(
    parseInt(year),
    parseInt(month) - 1,
    parseInt(day),
    parseInt(hour),
    parseInt(minute),
    parseInt(second || 0)
  );

  // Round up to the next minute if seconds or ms are non-zero
  if (nyDate.getSeconds() > 0 || nyDate.getMilliseconds() > 0) {
    nyDate.setSeconds(0, 0);
    nyDate.setMinutes(nyDate.getMinutes() + 1);
  }

  // 4. Format as "MM-DD-YYYY HH:mm EST" (using '-' instead of '/')
  const finalMonth = String(nyDate.getMonth() + 1).padStart(2, '0');
  const finalDay = String(nyDate.getDate()).padStart(2, '0');
  const finalYear = nyDate.getFullYear();
  const finalHour = String(nyDate.getHours()).padStart(2, '0');
  const finalMin = String(nyDate.getMinutes()).padStart(2, '0');

  return `${finalMonth}-${finalDay}-${finalYear} ${finalHour}:${finalMin} EST`;
};

// A card component for each history entry
const HistoryCard = ({ entry }) => {
  const [expanded, setExpanded] = useState(false);
  const toggleExpanded = () => setExpanded(!expanded);

  const { desc, timestamp, IDs, cluster_title } = entry;
  // Show only the first 3 IDs unless expanded
  const idsToShow = expanded ? IDs : IDs.slice(0, 3);

  return (
    <div style={cardStyle}>
      <div>
        <p style={timestampStyle}>{formatTimestamp(timestamp)}</p>
        <h4>Topic: {cluster_title}</h4>
        <p style={descStyle}>{desc}</p>
      </div>
      <div style={badgeContainerStyle}>
        {idsToShow.map((id, idx) => (
          <span key={id} style={badgeStyle}>
            <a
              href={`https://verbaai.org/article/${id}`}
              style={{ color: 'inherit', textDecoration: 'none' }}
              target="_blank" 
              rel="noopener noreferrer"
            >
              {`Source ${idx + 1}`}
            </a>
          </span>
        ))}
        {IDs.length > 3 && (
          <span
            onClick={toggleExpanded}
            style={toggleIconStyle}
            title={expanded ? "Show less" : "Show more"}
          >
            {expanded ? <HiMiniBarsArrowUp /> : <HiMiniBarsArrowDown />}
          </span>
        )}
      </div>
    </div>
  );
};

// Styling
const cardStyle = {
  background: "white",
  borderRadius: "0.5rem",
  boxShadow: "0 4px 8px rgba(0,0,0,0.2), 0 -4px 8px rgba(0,0,0,0.1)",
  padding: "1rem",
  maxWidth: "30rem",
  margin: "0 auto",
};

const timestampStyle = {
  fontSize: "1.2rem",
  color: "#6b7280",
  marginBottom: "0.5rem",
};

const descStyle = {
  fontWeight: 300,
  color: "#374151",
  marginBottom: "0.5rem",
};

const badgeContainerStyle = {
  marginTop: "0.75rem",
  display: "flex",
  flexWrap: "wrap",
  gap: "0.5rem",
  alignItems: "center",
};

const badgeStyle = {
  fontSize: "0.875rem",
  padding: "0.25rem 0.5rem",
  border: "1px solid #d1d5db",
  borderRadius: "9999px",
  background: "#f9fafb",
};

const toggleIconStyle = {
  cursor: "pointer",
  fontSize: "1.5rem",
  userSelect: "none",
  lineHeight: "1",
};

const RecentHistoryFeed = () => {
  const [data, setData] = useState([]);

  // Fetch and transform data
  const fetchData = async () => {
    try {
      const response = await axios.get('https://fopotracking.ue.r.appspot.com/recent_history');
      const transformedData = response.data.map(entry => ({
        doc_id: entry.doc_id || '',
        cluster_title: entry.cluster_title || '',
        IDs: Array.isArray(entry.IDs) ? entry.IDs : (entry.IDs ? [entry.IDs] : []),
        desc: entry.desc || '',
        timestamp: entry.timestamp.replace(" ", "T") + "+00:00" || ''
      }));
      setData(transformedData);
    } catch (error) {
      console.error("Error fetching recent history:", error);
    }
  };

  useEffect(() => {
    fetchData();
    const intervalId = setInterval(fetchData, 600000); // Refresh every 10 minutes
    return () => clearInterval(intervalId);
  }, []);

  // Compute the header timestamp from the data
  const headerTimestamp = data.length > 0 ? getHeaderTimestamp(data) : "Recent History Feed";

  return (
    <div>
      <h1 style={{ textAlign: 'center' }}>Alerts: {headerTimestamp}</h1>
      <br/>
      <div style={feedContainerStyle}>
        {data.length > 0 ? (
          data.map((entry, index) => (
            <HistoryCard key={index} entry={entry} />
          ))
        ) : (
          <p style={noDataTextStyle}>No recent history available.</p>
        )}
      </div>
    </div>
  );
};

// Container styles
const feedContainerStyle = {
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
};

const noDataTextStyle = {
  textAlign: 'center',
  fontSize: '18px',
  color: '#666'
};

export default RecentHistoryFeed;
