import '../GlobalStyles.css'; // Import the CSS file
import React, { useState, useEffect, useContext } from "react";
import "../GlobalStyles.css";
import SearchableTable from '../search/SearchableTable.js';
import { useUser } from "../../contexts/UserContext.js";
import Alert from "react-bootstrap/Alert";
import { Tab, Tabs } from 'react-bootstrap';
import SearchableCluster from '../search/SearchableCluster.js';


const HamasTracker = () => {

    return (
        <div>
            <h1 style={{ textAlign: 'center' }}>Hamas News Tracker</h1>
            <Tabs defaultActiveKey="narratives" id="uncontrolled-tab-example" className="border-3">
                <Tab eventKey="narratives" title="Narratives">
                    <div className="searchable-container">
                        <SearchableCluster
                            baseRequest={{"hamas": true}}
                            selectedSortType="Relevance"
                            hamasCluster={true}
                            trackerType={["hamas news"]}
                            preset_query='Hamas'
                        />
                    </div>
                </Tab>
                <Tab eventKey="articles" title="Article Feed">
                    <SearchableTable
                        showSentiment={true}
                        trackerType={["hamas news"]}
                        hamasCluster={true}
                    />
                </Tab>
                <Tab eventKey="about" title="About">
                    <br />
                    <h4>
                        This tracker monitors 230,000 news outlets in real-time for mentions of Hamas.
                        <br /><br /><br />
                    </h4>
                    <br />
                </Tab>
            </Tabs>
        </div>
    );
};

export default HamasTracker;
