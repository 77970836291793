import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

export default function IssueTrackers() {

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    useEffect(() => {
        function handleResize() {
            setIsMobile(window.innerWidth <= 768);
        }
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const buttonContainerStyle = isMobile ? { display: 'flex', flexDirection: 'column', alignItems: 'center' } : { textAlign: 'center' };

    const gridContainerStyle = {
        display: 'grid',
        gridTemplateColumns: isMobile ? '1fr' : '1fr 1fr', // One column on mobile, two columns otherwise
        textAlign: 'center',
        gap: '5px', // Space between columns
    };

    return (
        <div>
            <div className="text-content">
                <h1 style={{ textAlign: 'center' }}>Issue Trackers</h1>
                <h3 style={{ textAlign: 'center' }}>Explore global news coverage of the world's most pressing issues, all in one place.</h3>
                <h3 style={{ textAlign: 'center' }}>Click on an image to get started.</h3>
                <br/>
            </div>
            <div>
                <div style={gridContainerStyle}>
                    <div>
                        {/* Image dimensions need to be 1800 x 1350 px or in that ratio */}
                        <h3>Israel-Hamas War</h3>
                        <Link to="/issue-tracker/israel_hamas_war" className="btn-link">
                            <img src="https://i.postimg.cc/LsqWn80S/israel-hamas-war.webp" style={{ maxWidth: '90%', height: 'auto' }} />
                        </Link>
                        <br /><br />
                        <h3>Nuclear Weapons</h3>
                        <Link to="/issue-tracker/nuclear_weapons" className="btn-link">
                            <img src="https://i.imgur.com/MmM5tZG.png" style={{ maxWidth: '90%', height: 'auto' }} />
                        </Link>
                        <br /><br />
                        <h3>South China Sea</h3>
                        <Link to="/issue-tracker/south_china_sea" className="btn-link">
                            <img src="https://i.imgur.com/RSCwnff_d.webp?maxwidth=1520&fidelity=grand" style={{ maxWidth: '90%', height: 'auto' }} />
                        </Link>
                        <br /><br />
                    </div>
                    <div>
                        <h3>Russia-Ukraine War</h3>
                        <Link to="/issue-tracker/russia_ukraine_war" className="btn-link">
                            <img src="https://i.postimg.cc/gjKSfXrV/russia-ukraine-war.webp" style={{ maxWidth: '90%', height: 'auto' }} />
                        </Link>
                        <br /><br />
                        <h3>Climate Change</h3>
                        <Link to="/issue-tracker/climate_change" className="btn-link">
                            <img src="https://i.imgur.com/GwTmXmc_d.webp?maxwidth=1520&fidelity=grand" style={{ maxWidth: '90%', height: 'auto' }} />
                        </Link>
                        <br /><br />
                        <h3>Create A Custom Issue Tracker</h3>
                        <Link to="/mynews" className="btn-link">
                            <img src="/custom_tracker.png" style={{ maxWidth: '90%', height: 'auto' }} />
                        </Link>
                        <br /><br />
                    </div>
                </div>
            </div>
        </div>
    );
}
