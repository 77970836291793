import '../GlobalStyles.css'; // Import the CSS file
import React, { useState, useEffect, useContext } from "react";
import "../GlobalStyles.css";
import SearchableTable from '../search/SearchableTable.js';
import { useUser } from "../../contexts/UserContext.js";
import Alert from "react-bootstrap/Alert";
import { Tab, Tabs } from 'react-bootstrap';
import SearchableCluster from '../search/SearchableCluster.js';


const IranNews = () => {
    const { user } = useUser();
    const generalFilters = {
        countries: ["Iran"]
    };

    const iranNarrativeFilters = {
        "iran": true
    };


    return (
        <div>
            <h1 style={{ textAlign: 'center' }}>Iran News Tracker</h1>
            <Tabs defaultActiveKey="global-stories" id="uncontrolled-tab-example" className="border-3">
                <Tab eventKey="global-stories" title="Global Narratives">
                    <div className="searchable-container">
                        <SearchableCluster
                            baseRequest={generalFilters}
                            selectedSortType="Last Updated"
                            trackerType={["iran news"]}
                        />
                    </div>
                </Tab>
                {/* TODO: Add in iran exclusive cluster data here */}
                <Tab eventKey="domestic-stories" title="Iran Narratives">
                    <div className="searchable-container">
                        <SearchableCluster
                            baseRequest={iranNarrativeFilters}
                            selectedSortType="Last Updated"
                            iranCluster={true}
                            trackerType={["iran news"]}
                        />
                    </div>
                </Tab>
                <Tab eventKey="articles" title="Article Feed">
                    <SearchableTable
                        showCharged={true}
                        trackerType={["iran news"]}
                        countrySearch={false}
                        iranCluster={true}
                    />
                </Tab>
                <Tab eventKey="about" title="About">
                    <br />
                    <h4>
                        This tracker monitors emerging news on Iranian politics and security.
                        <br /><br />
                        <b><i>Global Narratives:</i></b> Insights on Iran from both Iranian and foreign news sources.
                        <br /><br />
                        <b><i>China Narratives:</i></b> Insights exclusively from 150 Iranian news sources.
                        <br /><br />
                        <b><i>Article Feed:</i></b> Articles on politics and security from 150 Iranian news sources.
                        <br /><br /><br />
                    </h4>
                    <br />
                </Tab>
            </Tabs>
        </div>
    );
};

export default IranNews;
