// DonutChart.js
import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

// Function to generate a random RGB color
const getRandomColor = () => {
  const random = () => Math.floor(Math.random() * 256);
  return `rgb(${random()}, ${random()}, ${random()})`;
};

const DonutChart = ({ title, data, labels, qualitativeParams=false }) => {
  let backgroundColors = [];

  if (qualitativeParams) {
    // Use the qualitative mapping if customParams is true.
    const qualitativeColorMapping = {
      "Other": 'rgb(192, 192, 192)',
      "None": 'rgb(192, 192, 192)',
      "No": 'rgb(192, 192, 192)',
      "Yes": 'rgb(242, 22, 22)',
      "Happiness": 'rgb(255, 182, 193)',
      "Sadness": 'rgb(17, 176, 179)',
      "Disgust": 'rgb(255, 190, 28)',
      "Anger": 'rgb(242, 22, 22)',
      "Fear": 'rgb(122, 203, 57)',
      "Surprise": 'rgb(46, 70, 155)',
    };

    // Ensure consistency: if a label isn’t in the mapping, assign it a random color.
    const assignedColors = {};
    const getQualitativeColor = (label) => {
      if (qualitativeColorMapping.hasOwnProperty(label)) {
        return qualitativeColorMapping[label];
      }
      if (assignedColors[label]) {
        return assignedColors[label];
      }
      const newColor = getRandomColor();
      assignedColors[label] = newColor;
      return newColor;
    };

    backgroundColors = labels.map((label) => getQualitativeColor(label));
  } else {
    // Ranking-based color assignment:
    // The slice with the largest data value gets rank 0,
    // the second largest gets rank 1, etc.
    const rankedColorMapping = {
      '0': 'rgb(192, 192, 192)', // Orchid
      '1': 'rgb(34, 139, 34)',   // Forest Green
      '2': 'rgb(243, 103, 77)',  // Salmon
      '3': 'rgb(17, 176, 179)',  // Turquoise
      '4': 'rgb(255, 190, 28)',  // Gold
      '5': 'rgb(122, 203, 57)',  // Lime Green
      '6': 'rgb(46, 70, 155)',   // Royal Blue
      '7': 'rgb(128, 0, 128)',   // Purple
      '8': 'rgb(255, 105, 180)', // Hot Pink
      '9': 'rgb(255, 165, 0)',   // Orange
      '10': 'rgb(242, 22, 22)',  // Red
      '11': 'rgb(70, 130, 180)', // Steel Blue
      '12': 'rgb(139, 69, 19)',  // Saddle Brown
      '13': 'rgb(255, 20, 147)', // Deep Pink
      '14': 'rgb(0, 128, 128)',  // Teal
      '15': 'rgb(210, 105, 30)', // Chocolate
      '16': 'rgb(255, 99, 71)',  // Tomato
      '17': 'rgb(75, 0, 130)',   // Indigo
      '18': 'rgb(255, 228, 196)',// Bisque
      '19': 'rgb(154, 205, 50)', // Yellow Green
      '20': 'rgb(199, 21, 133)', // Medium Violet Red
      '21': 'rgb(0, 255, 255)',  // Aqua
      '22': 'rgb(218, 112, 214)',// Orchid
      '23': 'rgb(255, 239, 213)',// Papaya Whip
      '24': 'rgb(64, 224, 208)', // Turquoise
    };

    // Create an array of indices sorted by data value (largest first)
    const sortedIndices = data
      .map((value, index) => index)
      .sort((a, b) => data[b] - data[a]);

    // Prepare the backgroundColors array in the original order.
    backgroundColors = new Array(data.length);
    sortedIndices.forEach((origIndex, rank) => {
      if (rank < Object.keys(rankedColorMapping).length) {
        backgroundColors[origIndex] = rankedColorMapping[rank.toString()];
      } else {
        backgroundColors[origIndex] = getRandomColor();
      }
    });
  }

  const chartData = {
    labels,
    datasets: [
      {
        data,
        backgroundColor: backgroundColors,
        borderColor: backgroundColors.map((color) =>
          color.replace('rgb', 'rgba').replace(')', ', 1.0)')
        ),
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          font: {
            family: 'Afacad',
            size: 15,
          },
          color: 'black',
        },
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem) => `${tooltipItem.raw} articles`,
        },
      },
    },
    cutout: '70%', // Makes the chart hollow
  };

  return (
    <div style={{ textAlign: 'center', marginBottom: '1rem', width: '100%', height: '100%' }}>
      <h4>{title}</h4>
      <div style={{ position: 'relative', width: '100%', height: '100%' }}>
        <Doughnut data={chartData} options={options} />
      </div>
    </div>
  );
};

export default DonutChart;
