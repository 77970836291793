// SearchableCluster.js
import React, { useState, useEffect } from "react";
import "./Search.css";
import "../GlobalStyles.css";
import DataTableCluster from "./DataTableCluster";
import axios from "axios";
import GridLoader from "react-spinners/GridLoader";
import SideBar from "./SideBar";

function SearchableCluster({
  baseRequest,
  preset_query = "",
  selectedSortType = "Last Updated",
  countrySearch = true,
  showSideBar = true,
  fringeCluster = false,
  turkeyCluster = false,
  chinaCluster = false,
  iranCluster = false,
  russiaCluster = false,
  usaCluster = false,
  hamasCluster = false,
  alQaedaCluster = false,
  bokoHaramCluster = false,
  hezbollahCluster = false,
  isisCluster = false,
  spaceForceCluster = false,
  utahCluster = false,
  clusterSimilarityThreshold = 0.6
}) {
  // ----------------------------
  // Constants and Helper Functions
  // ----------------------------

  // Define sort options for cluster view
  const clusterSortOptions = [
    { label: "Last Updated", value: "Last Updated" },
    { label: "Top Stories", value: "Top Stories" }
  ];

  // Static country options
  const countryOptions = [
    "Afghanistan", "Albania", "Algeria", "Andorra", "Angola", "Antigua and Barbuda", "Argentina",
    "Armenia", "Australia", "Austria", "Azerbaijan", "Bahamas", "Bahrain", "Bangladesh", "Barbados",
    "Belarus", "Belgium", "Belize", "Benin", "Bhutan", "Bolivia", "Bosnia and Herzegovina", "Botswana",
    "Brazil", "Brunei", "Bulgaria", "Burkina Faso", "Burundi", "Cabo Verde", "Cambodia", "Cameroon",
    "Canada", "Central African Republic", "Chad", "Chile", "China", "Colombia", "Comoros",
    "Congo (Brazzaville)", "Congo (Kinshasa)", "Costa Rica", "Croatia", "Cuba", "Cyprus",
    "Czech Republic", "Denmark", "Djibouti", "Dominica", "Dominican Republic", "Timor-Leste", "Ecuador",
    "Egypt", "El Salvador", "Equatorial Guinea", "Eritrea", "Estonia", "Eswatini", "Ethiopia",
    "European Union", "Fiji", "Finland", "France", "Gabon", "Gambia", "Georgia", "Germany", "Ghana",
    "Greece", "Grenada", "Guatemala", "Guinea", "Guinea-Bissau", "Guyana", "Haiti", "Honduras",
    "Hungary", "Iceland", "India", "Indonesia", "Iran", "Iraq", "Ireland", "Israel", "Italy",
    "Côte d'Ivoire", "Jamaica", "Japan", "Jordan", "Kazakhstan", "Kenya", "Kiribati", "North Korea",
    "South Korea", "Kosovo", "Kuwait", "Kyrgyzstan", "Laos", "Latvia", "Lebanon", "Lesotho",
    "Liberia", "Libya", "Liechtenstein", "Lithuania", "Luxembourg", "Madagascar", "Malawi", "Malaysia",
    "Maldives", "Mali", "Malta", "Marshall Islands", "Mauritania", "Mauritius", "Mexico", "Micronesia",
    "Moldova", "Monaco", "Mongolia", "Montenegro", "Morocco", "Mozambique", "Myanmar", "Namibia",
    "Nauru", "Nepal", "Netherlands", "New Zealand", "Nicaragua", "Niger", "Nigeria", "North Macedonia",
    "Norway", "Oman", "Pakistan", "Palau", "Palestine", "Panama", "Papua New Guinea", "Paraguay", "Peru",
    "Philippines", "Poland", "Portugal", "Qatar", "Romania", "Russia", "Rwanda",
    "Saint Kitts and Nevis", "Saint Lucia", "Saint Vincent and the Grenadines", "Samoa", "San Marino",
    "Sao Tome and Principe", "Saudi Arabia", "Senegal", "Serbia", "Seychelles", "Sierra Leone",
    "Singapore", "Slovakia", "Slovenia", "Solomon Islands", "Somalia", "South Africa", "South Sudan",
    "Spain", "Sri Lanka", "Sudan", "Suriname", "Sweden", "Switzerland", "Syria", "Taiwan", "Tajikistan",
    "Tanzania", "Thailand", "Togo", "Tonga", "Trinidad and Tobago", "Tunisia", "Turkey", "Turkmenistan",
    "Tuvalu", "Uganda", "Ukraine", "United Arab Emirates", "United Kingdom", "United States", "Uruguay",
    "Uzbekistan", "Vanuatu", "Vatican City", "Venezuela", "Vietnam", "Yemen", "Zambia", "Zimbabwe"
  ].map(country => ({ label: country, value: country }));

  // Define topic options (added to fix the undefined error)
  const topicOptions = [
    "Arts, Culture, Entertainment and Media",
    "Conflict, War and Peace",
    "Crime, Law and Justice",
    "Disaster, Accident and Emergency Incident",
    "Economy, Business and Finance",
    "Education",
    "Environment",
    "Health",
    "Human Interest",
    "Labour",
    "Lifestyle and Leisure",
    "Politics",
    "Religion",
    "Science and Technology",
    "Society",
    "Sport",
    "Weather"
  ];

  // Time range options for filtering by update time
  const timeRangeOptions = [
    { label: "Past 24 Hours", value: "Past 24 Hours" },
    { label: "Past Week", value: "Past Week" },
    { label: "Past Month", value: "Past Month" },
    { label: "Past 4 Months", value: "Past 4 Months" }
  ];

  // Helper to format a date in "YYYY-MM-DD HH:MM:SS" (UTC)
  const formatDateTime = (date) => {
    const pad = (n) => n.toString().padStart(2, "0");
    return `${date.getUTCFullYear()}-${pad(date.getUTCMonth() + 1)}-${pad(
      date.getUTCDate()
    )} ${pad(date.getUTCHours())}:${pad(date.getUTCMinutes())}:${pad(
      date.getUTCSeconds()
    )}`;
  };

  // ----------------------------
  // State Variables
  // ----------------------------

  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [noResults, setNoResults] = useState(false);
  const [numResults, setNumResults] = useState(10);
  const [hasMoreResults, setHasMoreResults] = useState(true);

  const [selectedClusterSort, setSelectedClusterSort] = useState(
    clusterSortOptions.find((option) => option.label === selectedSortType) || null
  );
  const [selectedSemanticSearch, setSelectedSemanticSearch] = useState("");
  const [selectedCountries, setSelectedCountries] = useState(null);
  const [selectedTopics, setSelectedTopics] = useState(null);
  const [selectedTimeRange, setSelectedTimeRange] = useState(
    timeRangeOptions.find((option) => option.value === "Past 24 Hours")
  );

  // Default filters used in the API request
  const now = new Date();
  const defaultFilters = {
    query: "",
    sources: [],
    countries: [],
    topics: [],
    keywords: [],
    update_timestamp: [
      formatDateTime(new Date(new Date().setDate(now.getDate() - 1))),
      formatDateTime(now)
    ],
    links: [],
    tracker_type: [],
    cluster: true,
    cluster_similarity_threshold: clusterSimilarityThreshold,
    set_size: numResults,
    get_biggest: selectedSortType === "Top Stories",
    get_last_update: selectedSortType === "Last Updated",
    smart: true,
    fringe: fringeCluster,
    china: chinaCluster,
    turkey: turkeyCluster,
    iran: iranCluster,
    russia: russiaCluster,
    united_states: usaCluster,
    hamas: hamasCluster,
    hezbollah: hezbollahCluster,
    boko_haram: bokoHaramCluster,
    al_qaeda: alQaedaCluster,
    isis: isisCluster,
    space: spaceForceCluster,
    utah: utahCluster
  };

  const initialFilters = { ...defaultFilters, ...baseRequest };
  const [filters, setFilters] = useState(initialFilters);

  // ----------------------------
  // Update Functions and Handlers
  // ----------------------------

  // Merge new filters into the existing filters state
  const updateFilters = (newFilters) =>
    setFilters((prev) => ({ ...prev, ...newFilters }));

  // Update only the update_timestamp field in filters
  const updateFiltersWithDates = (start, end) => {
    updateFilters({ update_timestamp: [start, end] });
  };

  // Update sort option when prop changes
  useEffect(() => {
    const sortOption = clusterSortOptions.find(
      (option) => option.label === selectedSortType
    );
    if (sortOption) {
      setSelectedClusterSort(sortOption);
    }
  }, [selectedSortType]);

  // Load data on component mount
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLoadMore = async () => {
    const newResults = numResults + 10;
    await fetchData(newResults);
    setNumResults(newResults);
  };

  const handleSemanticSearchChange = (e) => {
    const value = e.target.value;
    setSelectedSemanticSearch(value);
    updateFilters({ query: value });
  };

  const handleCountryChange = (selectedOption) => {
    setSelectedCountries(selectedOption);
    updateFilters({
      countries: selectedOption ? selectedOption.map((opt) => opt.value) : []
    });
  };

  const handleTopicChange = (selectedOption) => {
    setSelectedTopics(selectedOption);
    updateFilters({
      topics: selectedOption ? selectedOption.map((opt) => opt.value) : []
    });
  };

  const handleTimeRangeChange = (selectedOption) => {
    setSelectedTimeRange(selectedOption);
    const current = new Date();
    let startDate = current;
    let endDate = new Date(current.getTime() + 24 * 60 * 60 * 1000); // +1 day
    switch (selectedOption.value) {
      case "Past 24 Hours":
        startDate = new Date(current.getTime() - 24 * 60 * 60 * 1000);
        break;
      case "Past Week":
        startDate = new Date(current.getTime() - 7 * 24 * 60 * 60 * 1000);
        break;
      case "Past Month":
        startDate = new Date(current.setMonth(current.getMonth() - 1));
        break;
      case "Past 4 Months":
        startDate = new Date(current.setMonth(current.getMonth() - 4));
        break;
      default:
        break;
    }
    updateFiltersWithDates(formatDateTime(startDate), formatDateTime(endDate));
  };

  const handleClusterSortChange = (selectedOption) => {
    setSelectedClusterSort(selectedOption);
    if (selectedOption) {
      if (selectedOption.value === "Top Stories") {
        updateFilters({ get_biggest: true, get_last_update: false });
      } else if (selectedOption.value === "Last Updated") {
        updateFilters({ get_biggest: false, get_last_update: true });
      } else if (selectedOption.value === "Relevance") {
        updateFilters({ get_biggest: false, get_last_update: false });
      }
    } else {
      updateFilters({ get_biggest: undefined, get_last_update: undefined });
    }
  };

  const handleSearchClick = async () => {
    setNumResults(10);
    setIsLoading(true);
    setNoResults(false);
    try {
      await fetchData(10);
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  const handleRefreshClick = async () => {
    setIsLoading(true);
    try {
      await fetchData();
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  const fetchData = async (resultsToFetch = numResults) => {
    try {
      // Combine preset_query and filters.query (if any) into a single query string
      const combinedQuery = [preset_query, filters.query].filter(Boolean).join(" ");
      const updatedFilters = { ...filters, query: combinedQuery, set_size: resultsToFetch };
      const response = await axios.post(
        "https://fopotracking.ue.r.appspot.com/search",
        updatedFilters
      );
  
      const records = [];
      response.data.forEach((item) => {
        try {
          const {
            cluster_size,
            article_ids,
            cluster_title,
            cluster_summary,
            creation_timestamp,
            update_timestamp,
            persons,
            topics,
            countries,
            image_url,
            article_embeddings_reduced
          } = item.source;
  
          // Check if required fields exist (for example, creation_timestamp)
          if (!creation_timestamp || !update_timestamp) {
            throw new Error("Missing required timestamp");
          }
  
          records.push({
            cluster_id: item.id,
            cluster_size,
            article_ids,
            current_title: cluster_title,
            current_summary: cluster_summary,
            creation_timestamp_utc: creation_timestamp.replace(" ", "T") + "+00:00",
            update_timestamp_utc: update_timestamp.replace(" ", "T") + "+00:00",
            persons,
            topics,
            countries,
            image_link: image_url,
            article_embeddings_reduced
          });
        } catch (parseError) {
          console.error("Error parsing record:", parseError, item);
        }
      });
  
      if (selectedClusterSort) {
        if (selectedClusterSort.value === "Last Updated") {
          records.sort(
            (a, b) =>
              new Date(b.update_timestamp_utc) - new Date(a.update_timestamp_utc)
          );
        } else if (selectedClusterSort.value === "Top Stories") {
          records.sort((a, b) => b.cluster_size - a.cluster_size);
        }
      }
  
      setTableData(records);
      setIsLoading(false);
      setNoResults(records.length === 0);
      setHasMoreResults(records.length >= resultsToFetch);      
    } catch (error) {
      console.error("Error fetching latest data:", error);
      setHasMoreResults(false);
      setIsLoading(false);
    }
  };
  
  // ----------------------------
  // Rendered Components
  // ----------------------------

  const sidebar = showSideBar && (
    <div className="sidebar">
      <SideBar
        clusterSearch={true}
        selectedSemanticSearch={selectedSemanticSearch}
        onSemanticSearchChange={handleSemanticSearchChange}
        countryOptions={countryOptions}
        selectedCountries={selectedCountries}
        onCountryChange={handleCountryChange}
        countrySearch={countrySearch}
        topicOptions={topicOptions.sort().map((topic) => ({
          label: topic,
          value: topic
        }))}
        selectedTopics={selectedTopics}
        onTopicChange={handleTopicChange}
        clusterSortOptions={clusterSortOptions}
        selectedClusterSort={selectedClusterSort}
        onClusterSortChange={handleClusterSortChange}
        timeRangeOptions={timeRangeOptions}
        selectedTimeRange={selectedTimeRange}
        onTimeRangeChange={handleTimeRangeChange}
        handleSearchClick={handleSearchClick}
        handleRefreshClick={handleRefreshClick}
      />
    </div>
  );

  let dataTableContent;
  if (isLoading) {
    dataTableContent = (
      <div className="data-table" style={{ textAlign: "center" }}>
        <br />
        <br />
        <GridLoader />
      </div>
    );
  } else if (noResults) {
    dataTableContent = (
      <div className="data-table" style={{ textAlign: "center" }}>
        <br />
        <h2>No results found.</h2>
      </div>
    );
  } else {
    dataTableContent = (
      <div className="data-table">
        <DataTableCluster
          data={tableData}
          fringeCluster={fringeCluster}
          chinaCluster={chinaCluster}
          turkeyCluster={turkeyCluster}
          iranCluster={iranCluster}
          russiaCluster={russiaCluster}
          usaCluster={usaCluster}
          alQaedaCluster={alQaedaCluster}
          hamasCluster={hamasCluster}
          hezbollahCluster={hezbollahCluster}
          bokoHaramCluster={bokoHaramCluster}
          utahCluster={utahCluster}
          spaceForceCluster={spaceForceCluster}
          isisCluster={isisCluster}
        />
        <div style={{ textAlign: "center", marginTop: "1rem" }}>
          {hasMoreResults ? (
            <button className="btn-custom btn-charcoal-gray" onClick={handleLoadMore}>
              Load More
            </button>
          ) : (
            <h4>Showing all relevant results.</h4>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="searchable-container">
      {sidebar}
      {dataTableContent}
    </div>
  );
}

export default SearchableCluster;
