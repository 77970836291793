// SearchableTable.js
import React, { useState, useEffect } from 'react';
import './Search.css';
import '../GlobalStyles.css';
import SideBar from './SideBar';
import DataTable from './DataTable';
import GridLoader from 'react-spinners/GridLoader';
import axios from 'axios';
import { useFirestore } from "../../contexts/FirestoreContext";
import { getDatePublished, getTimePublished } from "../../utils.js/dateHelpers"


const SearchableTable = ({
    baseRequest,
    customTrackerCreator = false,
    countrySearch = true,
    countryColumn = true,
    languageSearch = true,
    languageColumn = true,
    sourceOptions: propSourceOptions,
    orgOptions: propOrgOptions,
    preset_query = "",
    excludePast24Hours = false,
    showSentiment = false,
    showCluster = true,
    showSideBar = true,
    shareArticle = false,
    congressTracker = false,
    fringeTracker = false,
    showCharged = false,
    showOrgs = false,
    personOptions = null,
    mediaMonitor = false,
    personOfInterest = null,
    orgOfInterest = null,
    orgsOfInterest = null,
    electionFactsTracker = false,
    trackerType = ["international politics"],
    articleSimilarityThreshold = 0.3,
    fringeCluster = false,
    chinaCluster = false,
    turkeyCluster = false,
    iranCluster = false,
    russiaCluster = false,
    usaCluster = false,
    hamasCluster = false,
    hezbollahCluster = false,
    isisCluster = false,
    bokoHaramCluster = false,
    alQaedaCluster = false,
    spaceForceCluster = false,
    utahCluster = false
}) => {

    // Helper functions for default date ranges
    const getPastWeekStartDate = () => {
        const now = new Date();
        const past7Days = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);
        return past7Days.toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        });
    };

    const getDefaultStartDate = () => {
        const now = new Date();
        const past24Hours = new Date(now.getTime() - 24 * 60 * 60 * 1000);
        return past24Hours.toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        });
    };

    const getDefaultEndDate = () => {
        const now = new Date();
        const tomorrow = new Date(now.setDate(now.getDate() + 1));
        return tomorrow.toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        });
    };

    // Table and query state
    const [numResults, setNumResults] = useState(10);
    const [hasMoreResults, setHasMoreResults] = useState(true);
    const [tableData, setTableData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [noResults, setNoResults] = useState(false);
    const [isInitialLoad, setIsInitialLoad] = useState(true);

    // Filter options state
    const [sourceOptions, setSourceOptions] = useState([]);
    const [languageOptions, setLanguageOptions] = useState([]);
    const [countryOptions, setCountryOptions] = useState([]);
    const [orgOptions, setOrgOptions] = useState([]);

    const { addCustomTracker, doesTrackerNameExist } = useFirestore();

    useEffect(() => {
        if (propSourceOptions) {
            setSourceOptions(propSourceOptions);
        } else {
            const fetchFilterOptions = async () => {
                try {
                    const response = await axios.post(
                        'https://fopotracking.ue.r.appspot.com/aggregations',
                        {
                            fields: ['website_title', 'language', 'country'],
                            fringe: fringeTracker,
                            tracker_type: trackerType
                        }
                    );
                    if (response.data) {
                        setSourceOptions(
                            response.data.website_title.map(source => ({
                                label: source,
                                value: source
                            }))
                        );
                        setLanguageOptions(
                            response.data.language.map(language => ({
                                label: language,
                                value: language
                            }))
                        );
                        setCountryOptions(
                            response.data.country.map(country => ({
                                label: country,
                                value: country
                            }))
                        );
                    }
                } catch (error) {
                    console.error('Error fetching filter options:', error);
                }
            };
            fetchFilterOptions();
        }
    }, [propSourceOptions, fringeTracker, trackerType]);

    useEffect(() => {
        if (propOrgOptions) {
            setOrgOptions(propOrgOptions);
        }
    }, [propOrgOptions]);

    // Define time range options for date filters.
    const timeRangeOptions = excludePast24Hours
        ? [
            { label: 'Past Week', value: 'Past Week' },
            { label: 'Past Month', value: 'Past Month' },
            { label: 'Past 4 Months', value: 'Past 4 Months' },
            { label: 'Custom Range', value: 'Custom Range' }
        ]
        : [
            { label: 'Past 24 Hours', value: 'Past 24 Hours' },
            { label: 'Past Week', value: 'Past Week' },
            { label: 'Past Month', value: 'Past Month' },
            { label: 'Past 4 Months', value: 'Past 4 Months' },
            { label: 'Custom Range', value: 'Custom Range' }
        ];

    // For cluster search (if needed; currently unused)
    const clusterSortOptions = [];
    const selectedClusterSort = null;
    const onClusterSortChange = () => { };

    const defaultFilters = {
        query: '',
        sources: [],
        countries: [],
        topics: [],
        date_published: excludePast24Hours
            ? [getPastWeekStartDate(), getDefaultEndDate()]
            : [getDefaultStartDate(), getDefaultEndDate()],
        links: [],
        tracker_type: [],
        article_id: [],
        article_similarity_threshold: articleSimilarityThreshold,
        latest: true,
        set_size: numResults,
        fringe: fringeCluster,
        china: chinaCluster,
        turkey: turkeyCluster,
        iran: iranCluster,
        russia: russiaCluster,
        united_states: usaCluster,
        hamas: hamasCluster,
        hezbollah: hezbollahCluster,
        boko_haram: bokoHaramCluster,
        al_qaeda: alQaedaCluster,
        isis: isisCluster,
        space: spaceForceCluster,
        utah: utahCluster
      };
      

    const initialFilters = { ...defaultFilters, ...baseRequest };
    const [filters, setFilters] = useState(initialFilters);
    const updateFilters = newFilters =>
        setFilters(prev => ({ ...prev, ...newFilters }));

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const fetchData = async (resultsToFetch = numResults) => {
        try {
            const combinedQuery = preset_query
                ? `${preset_query} ${filters.query}`.trim()
                : filters.query;
            const updatedFilters = {
                ...filters,
                query: combinedQuery,
                set_size: resultsToFetch
            };
            // console.log("L222 filters are", updatedFilters)
            const response = await axios.post(
                'https://fopotracking.ue.r.appspot.com/search',
                updatedFilters
            );
            // console.log("response is", response);
            setFilters(prev => ({ ...prev, latest: false }));
            const records = response.data.map(item => {
                const src = item.source;
                const utc = src.date_published.replace(' ', 'T') + '+00:00';
                const dateObj = new Date(src.date_published);
                return {
                    article_id: item.id,
                    date_time_published_utc: utc,
                    date_published: getDatePublished(utc),
                    time_published: getTimePublished(utc),
                    timezone_name: new Intl.DateTimeFormat('en-US', {
                        timeZoneName: 'short'
                    })
                        .format(dateObj)
                        .split(', ')[1],
                    link: src.link,
                    word_count: src.word_count,
                    title: src.title,
                    source_url: src.source_url,
                    country: src.country,
                    language: src.language,
                    source: src.website_name,
                    country_rank: src.country_rank,
                    site_rank: src.site_rank,
                    topics: src.topics,
                    locations: src.locations,
                    organizations: src.organizations,
                    persons: src.persons,
                    summary: src.summary,
                    text: src.text || null,
                    cluster_id: src.Cluster_ID || null,
                    image_link: src.image || null,
                    sentiment_compound: src.sentiment_compound || null,
                    charged: src.charged || null,
                    candidate_sentiment: src.candidate_sentiment || null,
                    c2pa_creds: src.c2pa_creds || null,
                    basic_emotion: src.basic_emotion || null
                };
            });
            setTableData(records);
            setIsLoading(false);
            setNoResults(records.length === 0);
            setHasMoreResults(records.length < resultsToFetch ? false : true);
        } catch (error) {
            console.error('Error fetching data:', error);
            setIsLoading(false);
        }
    };

    // Maintain local isMobile state for any mobile‐specific UI.
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 768);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Handlers for each search filter – all update the filters state.
    const [selectedSources, setSelectedSources] = useState(null);
    const handleSourceChange = selectedOption => {
        setSelectedSources(selectedOption);
        updateFilters({
            sources: selectedOption ? selectedOption.map(option => option.value) : []
        });
    };

    const [selectedOrgs, setSelectedOrgs] = useState(null);
    const handleOrgChange = selectedOption => {
        setSelectedOrgs(selectedOption);
        updateFilters({
            organizations: selectedOption ? selectedOption.map(option => option.value) : []
        });
    };

    const [selectedLanguages, setSelectedLanguages] = useState(null);
    const handleLanguageChange = selectedOption => {
        setSelectedLanguages(selectedOption);
        updateFilters({
            languages: selectedOption ? selectedOption.map(option => option.value) : []
        });
    };

    const [selectedCountries, setSelectedCountries] = useState(null);
    const handleCountryChange = selectedOption => {
        setSelectedCountries(selectedOption);
        updateFilters({
            countries: selectedOption ? selectedOption.map(option => option.value) : []
        });
    };

    const [selectedSentiments, setSelectedSentiments] = useState(null);
    const handleSentimentChange = selectedOption => {
        setSelectedSentiments(selectedOption);
        if (selectedOption && selectedOption.length > 0) {
            const selectedRanges = selectedOption.map(option =>
                mapSentimentToRange(option.value)
            );
            const minRange = Math.min(...selectedRanges.map(range => range[0]));
            const maxRange = Math.max(...selectedRanges.map(range => range[1]));
            updateFilters({ sentiment_compound: [minRange, maxRange] });
        } else {
            updateFilters({ sentiment_compound: [] });
        }
    };
    const sentimentOptions = [
        'Strongly Positive',
        'Moderately Positive',
        'Neutral',
        'Moderately Negative',
        'Strongly Negative'
    ].map(topic => ({ label: topic, value: topic }));
    const mapSentimentToRange = text => {
        switch (text) {
            case 'Strongly Negative':
                return [-1.0, -0.6];
            case 'Moderately Negative':
                return [-0.6, -0.2];
            case 'Neutral':
                return [-0.2, 0.2];
            case 'Moderately Positive':
                return [0.2, 0.6];
            case 'Strongly Positive':
                return [0.6, 1.0];
            default:
                return [null, null];
        }
    };

    const [selectedPersons, setSelectedPersons] = useState(null);
    const handlePersonChange = selectedOption => {
        setSelectedPersons(selectedOption);
        updateFilters({
            persons: selectedOption ? selectedOption.map(option => option.value) : []
        });
    };

    const [selectedFactCheckType, setSelectedFactCheckType] = useState(null);
    const handleFactCheckTypeChange = selectedOption => {
        setSelectedFactCheckType(selectedOption);
        updateFilters({
            fact_check_category: selectedOption
                ? selectedOption.map(option => option.value)
                : []
        });
    };
    const factCheckTypeOptions = [
        'Election Processes',
        'Election Outcomes',
        'Candidate Statements',
        'Campaign Messaging'
    ]
        .sort()
        .map(topic => ({ label: topic, value: topic }));

    const [selectedTopics, setSelectedTopics] = useState(null);
    const handleTopicChange = selectedOption => {
        setSelectedTopics(selectedOption);
        updateFilters({
            topics: selectedOption ? selectedOption.map(option => option.value) : []
        });
    };
    const topicOptions = [
        'Arts, Culture, Entertainment and Media',
        'Conflict, War and Peace',
        'Crime, Law and Justice',
        'Disaster, Accident and Emergency Incident',
        'Economy, Business and Finance',
        'Education',
        'Environment',
        'Health',
        'Human Interest',
        'Labour',
        'Lifestyle and Leisure',
        'Politics',
        'Religion',
        'Science and Technology',
        'Society',
        'Sport',
        'Weather'
    ]
        .sort()
        .map(topic => ({ label: topic, value: topic }));

    const [selectedTimeRange, setSelectedTimeRange] = useState(
        excludePast24Hours
            ? { label: 'Past Week', value: 'Past Week' }
            : { label: 'Past 24 Hours', value: 'Past 24 Hours' }
    );
    const handleTimeRangeChange = selectedOption => {
        setSelectedTimeRange(selectedOption);
        const now = new Date();
        let start, end;
        switch (selectedOption.value) {
            case 'Past 24 Hours':
                start = new Date(now.getTime() - 24 * 60 * 60 * 1000);
                end = new Date(now.getTime() + 1 * 24 * 60 * 60 * 1000);
                break;
            case 'Past Week':
                start = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);
                end = new Date(now.getTime() + 1 * 24 * 60 * 60 * 1000);
                break;
            case 'Past Month':
                start = new Date(now.setMonth(now.getMonth() - 1));
                end = new Date();
                break;
            case 'Past 4 Months':
                start = new Date('2024-01-01T00:00:00');
                end = new Date();
                break;
            case 'Custom Range':
                return;
            default:
                start = new Date();
                end = new Date(now.getTime() + 1 * 24 * 60 * 60 * 1000);
                break;
        }
        const formattedStartDate = start.toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        });
        const formattedEndDate = end.toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        });
        setStartDate(formattedStartDate);
        setEndDate(formattedEndDate);
        updateFilters({ date_published: [formattedStartDate, formattedEndDate] });
        fetchData();
    };

    const [trackerName, setTrackerName] = useState('');
    const [trackerNameError, setTrackerNameError] = useState('Enter a tracker name.');
    const handleTrackerNameChange = e => {
        const name = e.target.value;
        setTrackerName(name);
        const trimmedName = name.trim();
        if (trimmedName === '') {
            setTrackerNameError('Enter a tracker name.');
        } else if (doesTrackerNameExist(trimmedName)) {
            setTrackerNameError('Enter a unique tracker name.');
        } else {
            setTrackerNameError('');
        }
    };

    const [selectedSemanticSearch, setSelectedSemanticSearch] = useState('');
    const handleSemanticSearchChange = e => {
        const value = e.target.value;
        setSelectedSemanticSearch(value);
        updateFilters({ query: value });
    };

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const validateDateFormat = date =>
        /^(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01])\/\d{4}$/.test(date);
    const validateDateRange = (start, end) => {
        if (!start) return true;
        const endD = end ? new Date(end) : new Date();
        const startD = new Date(start);
        return startD < endD;
    };

    const [dateError, setDateError] = useState('');
    const handleStartDateChange = e => {
        const newStart = e.target.value;
        setStartDate(newStart);
        if (newStart === '') {
            setDateError('');
            updateFilters({ date_published: [newStart, endDate] });
            return;
        }
        if (!validateDateFormat(newStart)) {
            setDateError('Please use MM/DD/YYYY.');
        } else if (!validateDateRange(newStart, endDate)) {
            setDateError('Start date must be before end date.');
        } else {
            setDateError('');
            updateFilters({ date_published: [newStart, endDate] });
        }
    };

    const handleEndDateChange = e => {
        const newEnd = e.target.value;
        setEndDate(newEnd);
        if (newEnd === '') {
            setDateError('');
            updateFilters({ date_published: [startDate, newEnd] });
            return;
        }
        if (!validateDateFormat(newEnd)) {
            setDateError('Please use MM/DD/YYYY.');
        } else if (!validateDateRange(startDate, newEnd)) {
            setDateError('Start date must be before end date.');
        } else {
            setDateError('');
            updateFilters({ date_published: [startDate, newEnd] });
        }
    };

    const handleSearchClick = async () => {
        setNumResults(10);
        setIsInitialLoad(false);
        setIsLoading(true);
        setNoResults(false);
        try {
            await fetchData(10);
        } catch (error) {
            console.error('Error during API call:', error);
        }
    };

    const handleRefreshClick = async () => {
        handleSearchClick();
    };

    const [showNotificationBanner, setShowNotificationBanner] = useState(false);
    const [bannerText, setBannerText] = useState('');

    useEffect(() => {
        if (trackerName.trim() === '') {
            setTrackerNameError('Enter a tracker name.');
        } else if (doesTrackerNameExist(trackerName.trim())) {
            setTrackerNameError('Enter a unique tracker name.');
        } else {
            setTrackerNameError('');
        }
    }, [trackerName, doesTrackerNameExist]);

    const handleCreateTrackerClick = async () => {
        const trackerParams = {
            countries: selectedCountries ? selectedCountries.map(country => country.value) : [],
            date_published: [null, null],
            languages: selectedLanguages ? selectedLanguages.map(language => language.value) : [],
            query: selectedSemanticSearch,
            sources: selectedSources ? selectedSources.map(source => source.value) : [],
            organizations: selectedOrgs ? selectedOrgs.map(org => org.value) : [],
            topics: selectedTopics ? selectedTopics.map(topic => topic.value) : []
        };

        const newTracker = {
            trackerTitle: trackerName,
            trackerParams
        };

        try {
            await addCustomTracker(newTracker);
            setBannerText('Tracker Created!');
            setTrackerName('');
        } catch (error) {
            setBannerText('Error: ' + error.message);
        }
        setShowNotificationBanner(true);
        setTimeout(() => setShowNotificationBanner(false), 3000);
    };

    let dataTableContent;
    if (isLoading) {
        dataTableContent = (
            <div className="data-table" style={{ textAlign: 'center' }}>
                <br />
                <br />
                <GridLoader />
            </div>
        );
    } else if (noResults) {
        dataTableContent = (
            <div className="data-table" style={{ textAlign: 'center' }}>
                <br />
                <h2>No results found.</h2>
            </div>
        );
    } else {
        dataTableContent = (
            <>
                {showNotificationBanner && (
                    <div className="notification-banner">
                        <h3>{bannerText}</h3>
                    </div>
                )}
                <div className="data-table">
                    <DataTable
                        data={tableData}
                        semanticSearch={selectedSemanticSearch}
                        countryColumn={countryColumn}
                        languageColumn={languageColumn}
                        showSentiment={showSentiment}
                        showCluster={showCluster}
                        showCharged={showCharged}
                        personOfInterest={personOfInterest}
                        orgOfInterest={orgOfInterest}
                        orgsOfInterest={orgsOfInterest}
                        isInitialLoad={isInitialLoad}
                        shareArticle={shareArticle}
                        fringeCluster = {fringeCluster}
                        chinaCluster = {chinaCluster}
                        turkeyCluster = {turkeyCluster}
                        iranCluster = {iranCluster}
                        russiaCluster = {russiaCluster}
                        usaCluster = {usaCluster}
                        hamasCluster = {hamasCluster}
                        hezbollahCluster = {hezbollahCluster}
                        isisCluster = {isisCluster}
                        bokoHaramCluster = {bokoHaramCluster}
                        alQaedaCluster = {alQaedaCluster}
                        spaceForceCluster = {spaceForceCluster}
                        utahCluster = {utahCluster}
                    />
                    {!shareArticle && (
                        <div style={{ textAlign: 'center', marginTop: '1rem' }}>
                            {hasMoreResults ? (
                                <button
                                    className="btn-custom btn-charcoal-gray"
                                    onClick={() => {
                                        const newResults = numResults + 10;
                                        setNumResults(newResults);
                                        fetchData(newResults);
                                    }}
                                >
                                    Load More
                                </button>
                            ) : (
                                <h4>Showing all relevant results.</h4>
                            )}
                        </div>
                    )}
                </div>
            </>
        );
    }

    return (
        <div className="searchable-container">
            {showSideBar && (
                    <div className="sidebar">

                <SideBar
                    sourceOptions={sourceOptions}
                    selectedSources={selectedSources}
                    onSourceChange={handleSourceChange}
                    orgOptions={orgOptions}
                    selectedOrgs={selectedOrgs}
                    onOrgChange={handleOrgChange}
                    showOrgs={showOrgs}
                    languageOptions={languageOptions}
                    selectedLanguages={selectedLanguages}
                    onLanguageChange={handleLanguageChange}
                    languageSearch={languageSearch}
                    countryOptions={countryOptions}
                    selectedCountries={selectedCountries}
                    onCountryChange={handleCountryChange}
                    countrySearch={countrySearch}
                    topicOptions={topicOptions}
                    selectedTopics={selectedTopics}
                    onTopicChange={handleTopicChange}
                    sentimentOptions={sentimentOptions}
                    selectedSentiments={selectedSentiments}
                    onSentimentChange={handleSentimentChange}
                    showSentiment={showSentiment}
                    timeRangeOptions={timeRangeOptions}
                    selectedTimeRange={selectedTimeRange}
                    onTimeRangeChange={handleTimeRangeChange}
                    selectedSemanticSearch={selectedSemanticSearch}
                    onSemanticSearchChange={handleSemanticSearchChange}
                    startDate={startDate}
                    onStartDateChange={handleStartDateChange}
                    endDate={endDate}
                    onEndDateChange={handleEndDateChange}
                    dateError={dateError}
                    congressTracker={congressTracker}
                    personOptions={personOptions}
                    selectedPersons={selectedPersons}
                    onPersonChange={handlePersonChange}
                    customTrackerCreator={customTrackerCreator}
                    trackerName={trackerName}
                    onTrackerNameChange={handleTrackerNameChange}
                    trackerNameError={trackerNameError}
                    handleSearchClick={handleSearchClick}
                    handleRefreshClick={handleRefreshClick}
                    handleCreateTrackerClick={handleCreateTrackerClick}
                    electionFactsTracker={electionFactsTracker}
                    selectedFactCheckType={selectedFactCheckType}
                    onFactCheckChange={handleFactCheckTypeChange}
                    factCheckTypeOptions={factCheckTypeOptions}
                    clusterSortOptions={clusterSortOptions}
                    selectedClusterSort={selectedClusterSort}
                    onClusterSortChange={onClusterSortChange}
                    clusterSearch={false}
                />
                </div>
            )}
            {dataTableContent}
        </div>
    );
};

export default SearchableTable;
